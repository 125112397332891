import React from 'react';

export default function UpdateStatus({ onChangeStatus, status, order_to_update, updateOrderStatus }) {
	return (
		<div className="modal fade" id="update-status" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">Delete the user</div>
					<div className="modal-body">
						<div className="form-group">
							<select
								className="form-control"
								value={status !== null ? status : order_to_update ? order_to_update.info.status : null}
								onChange={e => onChangeStatus(e)}
							>
								<option>Pending</option>
								<option>Processing</option>
								<option>Completed</option>
								<option>Canceled</option>
							</select>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => updateOrderStatus(order_to_update.id, status)}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Save
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
