import { USERS, ADMIN_USERS, USER_EDIT_OR_DELETE } from '../constants/actionTypes';
const initialState = {
	users: [],
	admin_users: [],
	edit_or_delete: null,
};

function usersReducer(state = initialState, action) {
	switch (action.type) {
		case USERS:
			return { ...state, users: action.payload };
		case ADMIN_USERS:
			return { ...state, admin_users: action.payload };
		case USER_EDIT_OR_DELETE:
			return { ...state, edit_or_delete: action.payload };
		default:
			return state;
	}
}

export default usersReducer;
