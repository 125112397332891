import firebase from '../api/firebaseRef';
import { CATEGORIES, CATEGORIES_EDIT_OR_DELETE } from '../constants/actionTypes';
import history from '../history';
import { loaderState } from '.';

export const getCategories = () => dispatch => {
	let categoryRef = firebase.firestore().collection('categories');
	categoryRef
		.get()
		.then(categories => {
			let categoriesData = [];
			categories.docs.forEach(doc => {
				categoriesData.push({
					id: doc.id,
					info: doc.data(),
				});
			});
			dispatch({ type: CATEGORIES, payload: categoriesData });
			dispatch(loaderState(false));
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const deleteCategory = id => dispatch => {
	let categoryRef = firebase.firestore().collection('categories');
	categoryRef
		.doc(id)
		.delete()
		.then(async res => {
			history.go('/categories');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const addCategory = (name, file) => dispatch => {
	const storageRef = firebase.storage().ref('CATEGORIES_IMAGES');
	const categoryRef = firebase.firestore().collection('categories');

	const mainImage = storageRef.child(name);
	mainImage
		.put(file)
		.then(snapshot => {
			mainImage
				.getDownloadURL()
				.then(url => {
					categoryRef
						.add({
							categoryName: name,
							imageUrl: url,
						})
						.then(async file => {
							history.go('/categories');
						})
						.catch(err => {
							alert(err.message);
							dispatch(loaderState(false));
						});
				})
				.catch(err => {
					alert(err.message);
					dispatch(loaderState(false));
				});
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const categoryToEditOrDetele = category => dispatch => {
	dispatch({
		type: CATEGORIES_EDIT_OR_DELETE,
		payload: category,
	});
};

export const updateCategory = (id, newData) => dispatch => {
	const categoryRef = firebase.firestore().collection('categories');
	const storageRef = firebase.storage().ref('CATEGORIES_IMAGES');

	let data = null;
	if (newData.categoryName && newData.image) {
		data = {
			categoryName: newData.categoryName,
			imageUrl: 'test',
		};
	} else if (newData.categoryName) {
		data = {
			categoryName: newData.categoryName,
		};
	} else {
		data = {
			imageUrl: 'test',
		};
	}

	if (data.imageUrl) {
		const mainImage = storageRef.child(newData.image.name);
		mainImage
			.put(newData.image)
			.then(snapshot => {
				mainImage
					.getDownloadURL()
					.then(url => {
						data.imageUrl = url;
						categoryRef
							.doc(id)
							.update(data)
							.then(async cat => {
								history.go('/categories');
							})
							.catch(err => {
								alert(err.message);
								dispatch(loaderState(false));
							});
					})
					.catch(err => {
						alert(err.message);
						dispatch(loaderState(false));
					});
			})
			.catch(err => {
				alert(err.message);
				dispatch(loaderState(false));
			});
	} else {
		categoryRef
			.doc(id)
			.update(data)
			.then(async cat => {
				history.go('/categories');
			})
			.catch(err => {
				alert(err.message);
				dispatch(loaderState(false));
			});
	}
};
