import _ from "lodash";

export const paginationData = (dataSent, activePage, perPage) => {
  let actPage = activePage - 1;
  let pageStart = 0,
    pageEnd = perPage;
  if (activePage === 1) {
    pageStart = 0;
    pageEnd = perPage;
  } else {
    pageStart = actPage * perPage;
    pageEnd = pageStart + perPage;
  }

  let newData;
  newData = dataSent.slice(pageStart, pageEnd);

  return newData;
};

export const allPossiableSubStrings = (productName) => {
  var i,
    j,
    indexes = [];
  for (i = 0; i < productName.length; i++) {
    for (j = i + 1; j < productName.length + 1; j++) {
      indexes.push(productName.toUpperCase().slice(i, j));
    }
  }

  return indexes;
};

export const getProduct = (products, prodName) => {
  let product = null;
  _.forEach(products, (prod) => {
    if (prod.info.productName.trim() === prodName.trim()) {
      product = prod;
    }
  });

  return product;
};
