import React from 'react';
import ProductCard from './productCard';
import Pagination from '../common/pagination';
import AddEditProduct from './addEditProduct';
import DeleteProduct from './deleteProduct';
import ProductList from './productList';

export default function Products({
	products,
	onChangeCategory,
	categories,
	goto,
	onDeleteProduct,
	onSelectProduct,
	selected_product,
	onChangeImage,
	onChangeInput,
	onSaveProduct,
	selected_category,
}) {
	return (
		<div>
			<div className="row">
				<div className="col-xs-10">
					<div className="form-group">
						<select className="form-control" onChange={cat => onChangeCategory(cat)}>
							<option>All</option>
							{categories.map(cat => {
								return <option key={cat.id}>{cat.name}</option>;
							})}
						</select>
					</div>
				</div>
				<div className="col-xs-2">
					<div className="form-group" style={{ float: 'right' }}>
						<button data-toggle="modal" data-target="#add-edit-product" className="btn btn-info">
							Add New Product
						</button>
					</div>
				</div>
			</div>
			<div className="panel panel-default">
				<div className="panel-body" style={{ padding: '20px' }}>
					<ProductList
						onSelectProduct={product => onSelectProduct(product)}
						goto={path => goto(path)}
						products={products}
						selected_category={selected_category}
					/>
				</div>
			</div>

			<DeleteProduct selected_product={selected_product} onDeleteProduct={id => onDeleteProduct(id)} />
			<AddEditProduct
				onSaveProduct={() => onSaveProduct()}
				onChangeInput={e => onChangeInput(e)}
				onChangeImage={e => onChangeImage(e)}
				categories={categories}
				heading="Add New Product"
			/>
		</div>
	);
}
