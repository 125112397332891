import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateProfile, loaderState } from '../../actions';
import UserProfileComp from '../../components/userProfile';

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: null,
			last_name: null,
			// email: null,
			contact_number: null,
			address: null,
			city: null,
			postal_code: null,
		};
	}

	onChangeInput = event => {
		const { id, value } = event.target;
		if (id === 'first_name') {
			this.setState({
				first_name: value,
			});
		} else if (id === 'last_name') {
			this.setState({
				last_name: value,
			});
		} else if (id === 'city') {
			this.setState({
				city: value,
			});
		}
		// else if (id === 'email') {
		// 	this.setState({
		// 		email: value,
		// 	});
		// }
		else if (id === 'address') {
			this.setState({
				address: value,
			});
		} else if (id === 'contact_number') {
			this.setState({
				contact_number: value,
			});
		} else if (id === 'postal_code') {
			this.setState({
				postal_code: value,
			});
		}
	};

	onUpdateProfile = () => {
		this.props.loaderState(true);
		this.props.updateProfile(this.state);
	};

	render() {
		const { data } = this.props;
		return (
			<UserProfileComp
				info={this.state}
				onUpdateProfile={() => this.onUpdateProfile()}
				onChangeInput={e => this.onChangeInput(e)}
				data={data}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		data: state.auth.userInfo,
	};
};

export default connect(
	mapStateToProps,
	{ updateProfile, loaderState }
)(UserProfile);
