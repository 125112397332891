import React from 'react';

export default function UserList({ users, userToDeleteOrEdit }) {
	return (
		<div className="table-responsive">
			<table id="users-table" className="table table-hover">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Address</th>
						<th>Postal Code</th>
						<th>Status</th>
						<th style={{ textAlign: 'center' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users
						? users.map((user, index) => {
								return (
									<tr key={index}>
										<td>
											{user.info.first_name} {user.info.last_name}
										</td>
										<td>{user.info.email}</td>
										<td>{user.info.contactNumber}</td>
										<td>
											{user.info.address}, {user.info.city}
										</td>
										<td>{user.info.postalCode}</td>
										<td>
											<span
												className={
													user.info.status === 'active'
														? 'label label-success'
														: 'label label-danger'
												}
											>
												{user.info.status ? user.info.status.toUpperCase() : null}
											</span>
										</td>
										<td align="center">
											<a
												onClick={() => userToDeleteOrEdit(user)}
												data-toggle="modal"
												data-target="#delete-user"
											>
												<i
													style={{
														fontSize: '1.2em',
														cursor: 'pointer',
														marginRight: '20px',
														color: '#DD2B33',
													}}
													className={
														user.info.status === 'active'
															? 'fas fa-user-alt-slash'
															: 'fas fa-user-alt'
													}
													aria-hidden="true"
												></i>
											</a>
											<a
												data-toggle="modal"
												data-target="#edit-user"
												onClick={() => userToDeleteOrEdit(user)}
											>
												<i
													style={{ fontSize: '1.4em', cursor: 'pointer' }}
													className="fa fa-edit"
													aria-hidden="true"
												></i>
											</a>
										</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</table>
		</div>
	);
}
