export const LOADER = 'LOADER';
export const SELECTED_NAV = 'SELECTED_NAV';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const USERS = 'USERS';
export const ADMIN_USERS = 'ADMIN_USERS';
export const ORDERS = 'ORDERS';
export const ORDER_TO_UPDATE = 'ORDER_TO_UPDATE';
export const CATEGORIES = 'CATEGORIES';
export const CATEGORIES_EDIT_OR_DELETE = 'CATEGORIES_EDIT_OR_DELETE';
export const USER_EDIT_OR_DELETE = 'USER_EDIT_OR_DELETE';
export const BANNERS = 'BANNERS';
export const PRODUCTS = 'PRODUCTS';
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const NOTIFICATION_TEXT = 'NOTIFICATION_TEXT';
