import { CATEGORIES, CATEGORIES_EDIT_OR_DELETE } from '../constants/actionTypes';
const initialState = {
	categories: [],
	category_edit_or_delete: null,
};

function categoriesReducer(state = initialState, action) {
	switch (action.type) {
		case CATEGORIES:
			return { ...state, categories: action.payload };
		case CATEGORIES_EDIT_OR_DELETE:
			return { ...state, category_edit_or_delete: action.payload };
		default:
			return state;
	}
}

export default categoriesReducer;
