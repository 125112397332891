import React from "react";
import OrderItems from "./orderItems";
import pdfBtn from "../../assets/images/pdf.png";

export default function Order({
  order,
  user,
  total_amount,
  updateOrderStatus,
  onCreatePDF,
}) {
  return (
    <>
      <div className="row" id="order-panel">
        {user ? (
          <div className="col-lg-4 col-md-6 col-sm-6 col-8">
            <div className="panel panel-primary">
              <div className="panel-heading">Customer Information</div>
              <div className="panel-body">
                <div className="grid-info">
                  <div>Customer Name:</div>
                  <div>
                    {user.info.first_name} {user.info.last_name}
                  </div>
                </div>
                <div className="grid-info">
                  <div>Address: </div>
                  <div>{user.info.address}</div>
                </div>
                <div className="grid-info">
                  <div>City: </div>
                  <div>{user.info.city}</div>
                </div>
                <div className="grid-info">
                  <div>Postal Code: </div>
                  <div>{user.info.postalCode}</div>
                </div>
                <div className="grid-info">
                  <div>Email: </div>
                  <div>{user.info.email}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-lg-4"></div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-4">
          <div className="panel panel-primary">
            <div className="panel-heading">Order Date</div>
            <div className="panel-body">
              <div className="grid-info">
                <div>Date: </div>
                <div>{order.info.order_date}</div>
              </div>
              <div className="grid-info">
                <div>Id: </div>
                <div>{order.id}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="items-heading">
        {/* <label>Order Items</label> */}
        <div className="form-group">
          <div>Update Status:</div>
          <select
            className="form-control"
            value={order.info.status}
            onChange={(e) => updateOrderStatus(order.id, e.target.value)}
          >
            <option>Pending</option>
            <option>Processing</option>
            <option>Canceled</option>
            <option>Completed</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12" id="pdf-btn">
          <div
            className="pdf-btn-div"
            // className="btn btn-danger"
            onClick={() => onCreatePDF(order.id)}
          >
            <img src={pdfBtn} height="50px" width="150px" />
            {/* <i className="fa fa-file-pdf-o" style={{ marginRight: "5px" }} />
            Generate PDF */}
          </div>
          <div className="panel panel-default">
            <div className="panel-body" style={{ padding: "10px" }}>
              <OrderItems items={order.info.order_items} />
            </div>
          </div>
        </div>
      </div>
      <div className="row" id="order-panel">
        <div className="col-lg-8" style={{ textAlign: "right" }}></div>
        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
          <div
            className="panel panel-primary"
            style={{ marginTop: "-45px", height: "150px" }}
          >
            <div className="panel-heading">Total</div>
            <div className="panel-body" style={{ padding: "10px" }}>
              <div>
                <label>Sub Total: </label>
                <span>{total_amount}</span>
                <label>Shipping Amount: </label>
                <span>
                  {(
                    parseFloat(order.info.order_amount) -
                    parseFloat(total_amount)
                  ).toFixed(2)}
                </span>
                <hr />
                <label>Total: </label>
                <span>{order.info.order_amount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
