import { combineReducers } from 'redux';
import { LOADER, SELECTED_NAV } from '../constants/actionTypes';
import authReducer from './auth';
import usersReducer from './users';
import ordersReducer from './orders';
import categoriesReducer from './categories';
import bannersReducer from './banners';
import productsReducer from './products';
import settingsReducer from './settings';

function loaderReducer(state = { isLoading: false }, action) {
	switch (action.type) {
		case LOADER:
			return { ...state, isLoading: action.payload };
		default:
			return state;
	}
}

function navReducer(state = { nav: 'Dashboard' }, action) {
	switch (action.type) {
		case SELECTED_NAV:
			return { ...state, nav: action.payload };
		default:
			return state;
	}
}

export default combineReducers({
	loader: loaderReducer,
	selectedNav: navReducer,
	auth: authReducer,
	users: usersReducer,
	orders: ordersReducer,
	categories: categoriesReducer,
	banners: bannersReducer,
	products: productsReducer,
	settings: settingsReducer,
});
