import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import history from "../../history";
import { orderToUpdate, updateOrderStatus, getUsers, loaderState } from "../../actions";

import OrdersComp from "../../components/orders";

class Orders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
			statusData: "Pending"
		};
	}
	componentDidMount() {
		window.$(`#order-table`).DataTable({
			ordering: true,
			select: true,
			searching: true
		});
	}

	componentDidUpdate() {
		window.$(`#order-table`).DataTable({
			ordering: true,
			select: true,
			searching: true
		});
	}

	onChangeStatus = e => {
		this.setState({
			status: e.target.value
		});
	};

	onChangeStatusData = e => {
		this.setState({
			statusData: e.target.value
		});
	};

	getStatusData = orders => {
		let data = [];
		const { statusData } = this.state;
		if (statusData === "All") {
			return orders;
		} else {
			_.forEach(orders, order => {
				if (statusData === order.info.status) {
					data.push(order);
				}
			});
		}

		return data;
	};

	goto = path => {
		history.push(path);
		history.go(path);
	};

	render() {
		window
			.$(`#order-table`)
			.DataTable()
			.destroy();
		const { orders, users, order_to_update, orderToUpdate, updateOrderStatus } = this.props;
		const { status } = this.state;
		let statusData = this.getStatusData(orders);
		return (
			<OrdersComp
				getUserInfo={id => this.getUserInfo(id)}
				onChangeStatus={e => this.onChangeStatus(e)}
				onChangeStatusData={e => this.onChangeStatusData(e)}
				status={status}
				updateOrderStatus={(id, newStatus) => updateOrderStatus(id, newStatus)}
				users={users}
				order_to_update={order_to_update}
				orderToUpdate={data => orderToUpdate(data)}
				orders={statusData}
				goto={path => this.goto(path)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		orders: state.orders.orders,
		users: state.users.users,
		order_to_update: state.orders.order_to_update
	};
};
export default connect(
	mapStateToProps,
	{ orderToUpdate, updateOrderStatus, getUsers, loaderState }
)(Orders);
