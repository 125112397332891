import firebase from "../api/firebaseRef";
import { PRODUCTS, SELECTED_PRODUCT } from "../constants/actionTypes";
import { loaderState } from "./index";
import history from "../history";
import _ from "lodash";
import { allPossiableSubStrings, getProduct } from "../helpers";

export const getProducts = () => (dispatch) => {
  let productRef = firebase.firestore().collection("products");
  productRef
    .get()
    .then(async (products) => {
      let productsData = [];
      await products.docs.forEach((doc) => {
        productsData.push({
          id: doc.id,
          info: doc.data(),
        });
      });
      await dispatch({ type: PRODUCTS, payload: productsData });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const deleteProduct = (id) => (dispatch) => {
  let productRef = firebase.firestore().collection("products");
  productRef
    .doc(id)
    .delete()
    .then(async (res) => {
      dispatch(loaderState(false));
      history.go("/products");
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const selectedProduct = (product) => (dispatch) => {
  dispatch({ type: SELECTED_PRODUCT, payload: product });
};

export const addProduct = (data) => (dispatch) => {
  let productRef = firebase.firestore().collection("products");
  let storageRef = firebase.storage().ref("PRODUCT_IMAGES");
  let detailImages = [];
  let productPriceAfterSale = Number(data.productPrice);

  if (data.sale === "true") {
    productPriceAfterSale = Number(
      Number(
        data.productPrice -
          (Number(data.salePercent) / 100) * Number(data.productPrice)
      ).toFixed(2)
    );
  }

  let dataToSave = {
    imageUrl: "",
    detailImages: [],
    productName: data.productName.toUpperCase(),
    productPrice: Number(Number(data.productPrice).toFixed(2)),
    sale: data.sale ? (data.sale === "true" ? true : false) : false,
    salePercent: data.sale
      ? data.sale === "true"
        ? Number(data.salePercent)
        : 0
      : Number(0),
    indexes: data.indexes,
    featuredProduct: data.featuredProduct
      ? data.featuredProduct === "true"
        ? true
        : false
      : false,
    stock: Number(data.stock),
    category: data.category,
    description: data.description,
    productPriceAfterSale: Number(productPriceAfterSale),
  };
  const mainImage = storageRef.child(data.productName);
  mainImage
    .put(data.imageUrl)
    .then((snapshot) => {
      mainImage
        .getDownloadURL()
        .then(async (url) => {
          dataToSave.imageUrl = url;
          await _.forEach(data.detailImages, (img, index) => {
            if (index < 4) {
              var image = storageRef.child(data.productName + index);
              image
                .put(img)
                .then((snapshot) => {
                  image
                    .getDownloadURL()
                    .then((url) => {
                      detailImages.push(url);
                    })
                    .catch((err) => {
                      alert(err.message);
                      dispatch(loaderState(false));
                    });
                })
                .catch((err) => {
                  alert(err.message);
                  dispatch(loaderState(false));
                });
            }
          });

          setTimeout(() => {
            dataToSave.detailImages = detailImages;
            productRef
              .doc(data.productId)
              .set(dataToSave)
              .then(async (res) => {
                history.go("/products");
              })
              .catch((err) => {
                alert(err.message);
                dispatch(loaderState(false));
              });
          }, 5000);
        })
        .catch((err) => {
          alert(err.message);
          dispatch(loaderState(false));
        });
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const addNewImage = (image, index) => (dispatch, getState) => {
  let productRef = firebase.firestore().collection("products");
  let storageRef = firebase.storage().ref("PRODUCT_IMAGES");
  let selected_product = getState().products.selected_product;
  var imageName = selected_product.info.productName + index;
  let selected_product_images = selected_product.info.detailImages;

  const newImage = storageRef.child(imageName);

  newImage
    .put(image)
    .then((snapshot) => {
      newImage
        .getDownloadURL()
        .then((url) => {
          selected_product_images[index] = url;
          productRef
            .doc(selected_product.id)
            .update({ detailImages: selected_product_images })
            .then(async (res) => {
              await dispatch(getProducts());
              setTimeout(async () => {
                await dispatch(loaderState(false));
                history.go(`/products/${selected_product.id}`);
              }, 1000);
            })
            .catch((err) => {
              alert(err.message);
              dispatch(loaderState(false));
            });
        })
        .catch((err) => {
          alert(err.message);
          dispatch(loaderState(false));
        });
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const updateProductImage = (oldImage, newImage) => (
  dispatch,
  getState
) => {
  let productRef = firebase.firestore().collection("products");
  let storageRef = firebase.storage().ref("PRODUCT_IMAGES");
  let selected_product = getState().products.selected_product;
  if (selected_product.info.imageUrl === oldImage) {
    const mainImage = storageRef.child(selected_product.info.productName);
    mainImage
      .put(newImage)
      .then((snapshot) => {
        mainImage
          .getDownloadURL()
          .then((url) => {
            productRef
              .doc(selected_product.id)
              .update({ imageUrl: url })
              .then(async (res) => {
                await dispatch(getProducts());
                await dispatch(loaderState(false));
                history.go(`/products/${selected_product.id}`);
              })
              .catch((err) => {
                alert(err.message);
                dispatch(loaderState(false));
              });
          })
          .catch((err) => {
            alert(err.message);
            dispatch(loaderState(false));
          });
      })
      .catch((err) => {
        alert(err.message);
        dispatch(loaderState(false));
      });
  } else {
    let images = selected_product.info.detailImages;
    _.forEach(images, (img, index) => {
      if (img === oldImage) {
        var image = storageRef.child(selected_product.info.productName + index);
        image
          .put(newImage)
          .then((snapshot) => {
            image
              .getDownloadURL()
              .then((url) => {
                images[index] = url;
                productRef
                  .doc(selected_product.id)
                  .update({ detailImages: images })
                  .then(async (res) => {
                    await dispatch(getProducts());
                    setTimeout(async () => {
                      await dispatch(loaderState(false));
                      history.go(`/products/${selected_product.id}`);
                    }, 1000);
                  })
                  .catch((err) => {
                    alert(err.message);
                    dispatch(loaderState(false));
                  });
              })
              .catch((err) => {
                alert(err.message);
                dispatch(loaderState(false));
              });
          })
          .catch((err) => {
            alert(err.message);
            dispatch(loaderState(false));
          });
      }
    });
  }
};

export const updateProduct = (data) => (dispatch, getState) => {
  let productRef = firebase.firestore().collection("products");

  let selectedProduct = getState().products.selected_product;

  let dataToSave = {
    productPrice: Number(data.productPrice)
      ? Number(data.productPrice)
      : Number(selectedProduct.info.productPrice),
    sale: false,
    salePercent: Number(0),
    productPriceAfterSale: Number(data.productPrice)
      ? Number(Number(data.productPrice).toFixed(2))
      : Number(Number(selectedProduct.info.productPrice).toFixed(2)),
  };

  if (data.productName) {
    dataToSave.productName = data.productName.toUpperCase();
    dataToSave.indexes = allPossiableSubStrings(data.productName);
  }
  if (data.sale && data.sale === "true") {
    if (data.salePercent !== selectedProduct.info.salePercent) {
      dataToSave.productPriceAfterSale = Number(
        Number(
          (data.productPrice
            ? data.productPrice
            : selectedProduct.info.productPrice) -
            (Number(data.salePercent) / 100) *
              Number(
                data.productPrice
                  ? data.productPrice
                  : selectedProduct.info.productPrice
              )
        ).toFixed(2)
      );
      dataToSave.sale = true;
      dataToSave.salePercent = Number(data.salePercent);
    }
  }
  if (data.featuredProduct) {
    dataToSave.featuredProduct = data.featuredProduct === "true" ? true : false;
  }
  if (data.stock) {
    dataToSave.stock = Number(data.stock);
  }
  if (data.category) {
    dataToSave.category = data.category;
  }
  if (data.description) {
    dataToSave.description = data.description;
  }
  productRef
    .doc(selectedProduct.id)
    .update(dataToSave)
    .then(async (res) => {
      await dispatch(getProducts());
      setTimeout(async () => {
        await dispatch(loaderState(false));
        history.go(`/products/${selectedProduct.id}`);
      }, 1000);
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const updateStock = (orderItems) => (dispatch, getState) => {
  const { products } = getState().products;
  let productRef = firebase.firestore().collection("products");

  _.forEach(orderItems, (item, index) => {
    let product = getProduct(products, item.productName);

    let dataToSave = {
      stock: Number(
        Number(product.info.stock) - Number(item.purchased_quantity)
      ),
    };
    productRef
      .doc(product.id)
      .update(dataToSave)
      .then(async (res) => {
        console.log("product " + product.info.productName + " stock updated!");
      })
      .catch((err) => {
        alert(err.message);
      });

    if (orderItems.length === index + 1) {
      dispatch(getProducts());
    }
  });
};
