import firebase from '../api/firebaseRef';
import { getAdminUsersById, loaderState } from './index';
import { LOGGED_IN_USER } from '../constants/actionTypes';
import history from '../history';
import cookie from 'react-cookies';

export const authenticateUser = (email, password) => dispatch => {
	var expires = Date.now();
	expires += 1000 * 60 * 60 * 24 * 2;
	expires = new Date(expires);
	firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.then(async user => {
			cookie.save('userId', user.user.uid, { path: '/', expires: expires, maxAge: 1000 });
			await dispatch(getAdminUsersById(user.user.uid));
		})
		.catch(error => {
			alert('Incorrect username or password');
			dispatch(loaderState(false));
		});
};

export const signOut = () => dispatch => {
	firebase
		.auth()
		.signOut()
		.then(() => {
			cookie.remove('userId', { path: '/' });
			history.push('/');
			history.go('/');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const updateProfile = newData => (dispatch, getState) => {
	let userRef = firebase.firestore().collection('admin_users');
	const data = getState().auth.userInfo;
	let dataToSave = {
		address: newData.address !== null && newData.address !== '' ? newData.address : data.info.address,
		city: newData.city !== null && newData.city !== '' ? newData.city : data.info.city,
		first_name:
			newData.first_name !== null && newData.first_name !== '' ? newData.first_name : data.info.first_name,
		last_name: newData.last_name !== null && newData.last_name !== '' ? newData.last_name : data.info.last_name,
		// email: newData.email !== null && newData.email !== '' ? newData.email : data.info.email,
		postalCode:
			newData.postal_code !== null && newData.postal_code !== '' ? newData.postal_code : data.info.postalCode,
		contactNumber:
			newData.contact_number !== null && newData.contact_number !== ''
				? newData.contact_number
				: data.info.contactNumber,
	};
	userRef
		.doc(data.id)
		.update(dataToSave)
		.then(async res => {
			dispatch(loaderState(false));
			await dispatch(getLoogedInUser(data.id));
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const getLoogedInUser = id => dispatch => {
	let usersRef = firebase
		.firestore()
		.collection('admin_users')
		.doc(id);
	usersRef
		.get()
		.then(async doc => {
			await dispatch({
				type: LOGGED_IN_USER,
				payload: { id: doc.id, info: doc.data() },
			});
			dispatch(loaderState(false));
			history.push('/profile');
			history.go('/profile');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};
