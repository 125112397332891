import React from "react";
import _ from "lodash";

function getUserInfo(id, users) {
  let userData = null;
  _.forEach(users, (user) => {
    if (user.id === id) {
      userData = user;
    }
  });
  return userData;
}

export default function OrderData({ orders, users, orderToUpdate, goto }) {
  return (
    <div className="table-responsive">
      <table id="order-table" className="table table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Order By</th>
            <th>Order Amount</th>
            <th>Order Date</th>
            <th>Status</th>
            <th>Action</th>
            <th>Item</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => {
            let orderBy = getUserInfo(order.info.order_by, users);

            let status = order.info.status;
            return (
              <tr key={index}>
                <td>{order.id}</td>
                <td>
                  {orderBy ? orderBy.info.first_name + " " : null}
                  {orderBy ? orderBy.info.last_name : null}
                </td>
                <td>{order.info.order_amount}</td>
                <td>{order.info.order_date}</td>
                <td>
                  <span
                    className={`label label-${
                      status === "Completed"
                        ? "success"
                        : status === "Pending"
                        ? "warning"
                        : status === "Canceled"
                        ? "danger"
                        : status === "Processing"
                        ? "default"
                        : ""
                    }`}
                  >
                    {status}
                  </span>
                </td>
                <td id="product-btn">
                  <button
                    onClick={() => orderToUpdate(order)}
                    data-toggle="modal"
                    data-target="#update-status"
                    className="btn btn-info btn-xs"
                  >
                    Update Status
                  </button>
                </td>
                <td
                  onMouseOver={() => orderToUpdate(order)}
                  onClick={() => goto(`/orders/${order.id}`)}
                  title="click to view order details"
                >
                  <a>
                    <i
                      style={{ fontSize: "1.4em" }}
                      className="fa fa-eye"
                      aria-hidden="true"
                    ></i>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
