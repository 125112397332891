import React from 'react';

export default function DeleteProduct({ selected_product, onDeleteProduct }) {
	return (
		<div className="modal fade" id="delete-product" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header"></div>
					<div className="modal-body">
						<p>
							Are you sure you want to delete product "
							{selected_product ? selected_product.info.productName : null}"
						</p>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onDeleteProduct(selected_product.id)}
							className="btn btn-danger"
							data-dismiss="modal"
						>
							Confirm
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
