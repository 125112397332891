import React from 'react';

export default function UpdateProfile({ data, onChangeInput, info, onUpdateProfile }) {
	return (
		<div className="modal fade" id="update-profile" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content" style={{ padding: '10px' }}>
					<div className="modal-header">Edit User</div>
					<div className="modal-body">
						<div className="row">
							<div className="form-group">
								<label htmlFor="first_name">First Name</label>
								<input
									type="text"
									placeholder="First Name"
									value={info.first_name !== null ? info.first_name : data.info.first_name}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="first_name"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="last_name">Last Name</label>
								<input
									type="text"
									placeholder="Last Name"
									value={info.last_name !== null ? info.last_name : data.info.last_name}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="last_name"
								/>
							</div>
							{/*
								<div className="form-group">
									<label htmlFor="email">Email</label>

									<input
										type="email"
										placeholder="Email"
										value={info.email !== null ? info.email : data.info.email}
										onChange={e => onChangeInput(e)}
										className="form-control"
										id="email"
									/>
							</div> */}
							<div className="form-group">
								<label htmlFor="contact_number">Contact Number</label>
								<input
									type="text"
									placeholder="Contact Number"
									value={info.contact_number !== null ? info.contact_number : data.info.contactNumber}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="contact_number"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="address">Address</label>
								<input
									type="text"
									placeholder="Address"
									value={info.address !== null ? info.address : data.info.address}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="address"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="city">City</label>
								<input
									type="text"
									placeholder="City"
									value={info.city !== null ? info.city : data.info.city}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="city"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="postal _code">Postal Code</label>
								<input
									type="text"
									placeholder="Postal Code"
									value={info.postal_code !== null ? info.postal_code : data.info.postalCode}
									onChange={e => onChangeInput(e)}
									className="form-control"
									id="postal_code"
								/>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdateProfile()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
