import React from 'react';

export default function List({ categories, onEditDelete, onSearchTextChange }) {
	return (
		<div className="panel panel-default">
			<div className="panel-heading">
				<div className="form-group">
					<input
						type="text"
						onChange={e => onSearchTextChange(e)}
						placeholder="Search category by name..."
						className="form-control input-lg"
						id="search-category"
					/>
				</div>
			</div>
			<div className="panel-body">
				<ul className="list-group">
					{categories.map(cat => {
						return (
							<>
								<li className="list-group-item">
									<div className="checkbox">
										<img width="90px" src={`${cat.info.imageUrl}`} />
										{cat.info.categoryName}
									</div>
									<div className="action-buttons">
										<button
											data-toggle="modal"
											data-target="#edit-category"
											className="btn btn-default"
											onClick={() => onEditDelete(cat)}
										>
											Edit
										</button>
										<button
											className="btn btn-danger"
											data-toggle="modal"
											data-target="#delete-category"
											onClick={() => onEditDelete(cat)}
										>
											Delete
										</button>
									</div>
								</li>
							</>
						);
					})}
				</ul>
			</div>
		</div>
	);
}
