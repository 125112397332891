import React from 'react';
import { Link } from 'react-router-dom';

export default function Sidebar({ nav, onChangeNav }) {
	return (
		<>
			<a id="show-sidebar" className="btn btn-sm btn-dark" href="#">
				<i className="fas fa-bars"></i>
			</a>
			<nav id="sidebar" className="sidebar-wrapper">
				<div className="sidebar-content">
					<div className="sidebar-brand">
						<a href="#">Globuss Admin</a>
						<div id="close-sidebar">
							<i className="fas fa-times"></i>
						</div>
					</div>
					<div className="sidebar-menu">
						<ul>
							<li className="header-menu" style={{ height: '30px' }}></li>
							<li className={nav === 'Dashboard' ? 'sidenav-active' : ''}>
								<Link to="/dashboard" onClick={() => onChangeNav('Dashboard')} href="#">
									<i className="fa fa-tachometer-alt"></i>
									<span>Dashboard</span>
								</Link>
							</li>
							<li className={nav === 'Categories' ? 'sidenav-active' : ''}>
								<Link to="/categories" onClick={() => onChangeNav('Categories')} href="#">
									<i className="fa fa-list-alt"></i>
									<span>Categories</span>
								</Link>
							</li>
							<li className={nav === 'Products' ? 'sidenav-active' : ''}>
								<Link to="/products" onClick={() => onChangeNav('Products')} href="#">
									<i className="fa fa-database"></i>
									<span>Products</span>
								</Link>
							</li>
							<li className={nav === 'Orders' ? 'sidenav-active' : ''}>
								<Link to="/orders" onClick={() => onChangeNav('Orders')} href="#">
									<i className="fa fa-shopping-cart"></i>
									<span>Orders</span>
								</Link>
							</li>
							<li className={nav === 'Home Page Banners' ? 'sidenav-active' : ''}>
								<Link to="/banners" onClick={() => onChangeNav('Home Page Banners')} href="#">
									<i className="fa fa-picture-o"></i>
									<span>Home Page Banners</span>
								</Link>
							</li>
							<li className={nav === 'Users' ? 'sidenav-active' : ''}>
								<Link to="/users" onClick={() => onChangeNav('Users')} href="#">
									<i className="fa fa-users"></i>
									<span>Users</span>
								</Link>
							</li>
							<li className={nav === 'Admin Users' ? 'sidenav-active' : ''}>
								<Link to="/admin users" onClick={() => onChangeNav('Admin Users')} href="#">
									<i className="fa fa-users"></i>
									<span>Admin Users</span>
								</Link>
							</li>
							<li className={nav === 'Settings' ? 'sidenav-active' : ''}>
								<Link to="/settings" onClick={() => onChangeNav('Settings')} href="#">
									<i className="fa fa-cog"></i>
									<span>Settings</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
