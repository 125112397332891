import React from 'react';

export default function Settings({
	text,
	onChangeText,
	notification,
	onUpdateNotification,
	privacy,
	delivery_instructions,
	onUpdatePrivacy,
	onUpdateDeliveryInstructions,
}) {
	return (
		<div className="container">
			<label>Notification Text:</label>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row">
						<div className="col-sm-10">
							<div className="form-group">
								<input
									type="text"
									onChange={e => onChangeText(e)}
									value={notification !== null ? notification : text.length > 0 ? text[1].text : ''}
									className="form-control"
									id="notification"
								/>
							</div>
						</div>
						<div className="col-sm-2" id="update-notification">
							<button onClick={() => onUpdateNotification()} className="btn btn-success">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
			<label>Privacy Text:</label>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row">
						<div className="col-sm-10">
							<div className="form-group">
								<textarea
									style={{ resize: 'none' }}
									rows="7"
									type="text"
									onChange={e => onChangeText(e)}
									value={privacy !== null ? privacy : text.length > 0 ? text[2].text : ''}
									className="form-control"
									id="privacy"
								/>
							</div>
						</div>
						<div className="col-sm-2" id="update-notification">
							<button onClick={() => onUpdatePrivacy()} className="btn btn-success">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
			<label>Delivery Instructions:</label>
			<div className="panel panel-default">
				<div className="panel-body">
					<div className="row">
						<div className="col-sm-10">
							<div className="form-group">
								<textarea
									style={{ resize: 'none' }}
									rows="5"
									type="text"
									onChange={e => onChangeText(e)}
									value={
										delivery_instructions !== null
											? delivery_instructions
											: text.length > 0
											? text[0].text
											: ''
									}
									className="form-control"
									id="delivery_instructions"
								/>
							</div>
						</div>
						<div className="col-sm-2" id="update-notification">
							<button onClick={() => onUpdateDeliveryInstructions()} className="btn btn-success">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
