import React from 'react';
import { Link } from 'react-router-dom';
export default function Quantities({ counts, onChangeNav }) {
	return (
		<div className="" style={{ padding: '15px' }}>
			<div className="row">
				<div className="col-lg-3">
					<div className="panel panel-info">
						<div className="panel-heading">
							<div className="row">
								<div className="col-xs-6">
									<i className="fa fa-users fa-5x"></i>
								</div>
								<div className="col-xs-6 text-right">
									<p className="announcement-heading">{counts.adminUsers}</p>
									<p className="announcement-text">Admin User</p>
								</div>
							</div>
						</div>
						<Link to="/admin users" onClick={() => onChangeNav('Admin Users')}>
							<div className="panel-footer announcement-bottom">
								<div className="row">
									<div className="col-xs-6"></div>
									<div className="col-xs-6 text-right">
										<i style={{ fontSize: '1.4em' }} className="fa fa-eye"></i>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="panel panel-danger">
						<div className="panel-heading">
							<div className="row">
								<div className="col-xs-6">
									<i className="fa fa-users fa-5x"></i>
								</div>
								<div className="col-xs-6 text-right">
									<p className="announcement-heading">{counts.users}</p>
									<p className="announcement-text">Users</p>
								</div>
							</div>
						</div>
						<Link to="/users" onClick={() => onChangeNav('Users')}>
							<div className="panel-footer announcement-bottom">
								<div className="row">
									<div className="col-xs-6"></div>
									<div className="col-xs-6 text-right">
										<i style={{ fontSize: '1.4em' }} className="fa fa-eye"></i>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="panel panel-warning">
						<div className="panel-heading">
							<div className="row">
								<div className="col-xs-6">
									<i className="fa fa-barcode fa-5x"></i>
								</div>
								<div className="col-xs-6 text-right">
									<p className="announcement-heading">{counts.products}</p>
									<p className="announcement-text"> Products</p>
								</div>
							</div>
						</div>
						<Link to="/products" onClick={() => onChangeNav('Products')}>
							<div className="panel-footer announcement-bottom">
								<div className="row">
									<div className="col-xs-6"></div>
									<div className="col-xs-6 text-right">
										<i style={{ fontSize: '1.4em' }} className="fa fa-eye"></i>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="panel panel-success">
						<div className="panel-heading">
							<div className="row">
								<div className="col-xs-6">
									<i className="fa fa-shopping-cart fa-5x"></i>
								</div>
								<div className="col-xs-6 text-right">
									<p className="announcement-heading">{counts.orders.length}</p>
									<p className="announcement-text"> Orders</p>
								</div>
							</div>
						</div>
						<Link to="/orders" onClick={() => onChangeNav('Orders')}>
							<div className="panel-footer announcement-bottom">
								<div className="row">
									<div className="col-xs-6"></div>
									<div className="col-xs-6 text-right">
										<i style={{ fontSize: '1.4em' }} className="fa fa-eye"></i>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="panel panel-warning">
						<div className="panel-heading">
							<div className="row">
								<div className="col-xs-6">
									<i className="fa fa-list-alt fa-5x"></i>
								</div>
								<div className="col-xs-6 text-right">
									<p className="announcement-heading">{counts.categories}</p>
									<p className="announcement-text"> Categories</p>
								</div>
							</div>
						</div>
						<Link to="/categories" onClick={() => onChangeNav('Categories')}>
							<div className="panel-footer announcement-bottom">
								<div className="row">
									<div className="col-xs-6"></div>
									<div className="col-xs-6 text-right">
										<i style={{ fontSize: '1.4em' }} className="fa fa-eye"></i>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
