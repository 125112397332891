import React from 'react';

export default function ConfirmationModal() {
	return (
		<div className="modal fade" id="confirmationModal" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						{/* <button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">Modal Header</h4> */}
					</div>
					<div className="modal-body">
						<p>Are you sure you want to delete this user?</p>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger" data-dismiss="modal">
							Confirm
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
