import React, { Component } from 'react';
import { getProducts, loaderState, deleteProduct, selectedProduct, addProduct } from '../../actions';
import { connect } from 'react-redux';
import { paginationData, allPossiableSubStrings } from '../../helpers';
import _ from 'lodash';
import history from '../../history';
import $ from 'jquery';

import ProductsComp from '../../components/products';

class Products extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			perPage: 4,
			product_category: 'All',
			categories: [],
			category: null,
			productName: null,
			featuredProduct: null,
			imageUrl: null,
			description: null,
			detailImages: null,
			productPrice: null,
			sale: null,
			salePercent: null,
			stock: null,
			productId: null,
			indexes: [],
		};
	}

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber });
	};

	async componentDidMount() {
		this.props.loaderState(true);
		this.categoriesList();
		await this.props.getProducts();
	}

	componentDidUpdate() {
		window.$(`#product-table`).DataTable({
			ordering: true,
			select: true,
			searching: true,
		});
	}

	onChangeCategory = category => {
		this.setState({ product_category: category.target.value });
	};

	categoriesList = () => {
		const { categories } = this.props;
		let list = [];
		_.forEach(categories, cat => {
			list.push({
				id: cat.id,
				name: cat.info.categoryName,
			});
		});

		this.setState({ categories: list });
	};

	goto = path => {
		history.push(path);
		history.go(path);
	};

	getCategoryProducts = products => {
		const { categories, product_category } = this.state;
		let cat_products = [];
		if (product_category === 'All') {
			return products;
		}
		let cat_id = '';
		_.forEach(categories, cat => {
			if (cat.name.trim() === product_category.trim()) {
				cat_id = cat.id;
			}
		});
		_.forEach(products, product => {
			if (product.info.category === cat_id) {
				cat_products.push(product);
			}
		});
		return cat_products;
	};

	onDeleteProduct = id => {
		this.props.deleteProduct(id);
	};

	onSelectProduct = product => {
		this.props.selectedProduct(product);
	};

	getCategoryId = name => {
		const { categories } = this.state;

		let id = null;
		_.forEach(categories, cat => {
			if (cat.name.trim() === name.trim()) {
				id = cat.id;
			}
		});
		return id;
	};

	onChangeInput = e => {
		const { id, value } = e.target;
		if (id === 'category') {
			this.setState({
				category: this.getCategoryId(value),
			});
		} else if (id === 'product-name') {
			this.setState({ productName: value, indexes: allPossiableSubStrings(value) });
		} else if (id === 'product-price') {
			this.setState({ productPrice: value });
		} else if (id === 'product-id') {
			this.setState({ productId: value });
		} else if (id === 'featured-product') {
			this.setState({ featuredProduct: value });
		} else if (id === 'sale') {
			this.setState({ sale: value });
		} else if (id === 'stock') {
			this.setState({ stock: value });
		} else if (id === 'sale-percentage') {
			this.setState({ salePercent: value });
		} else {
			this.setState({
				description: value,
			});
		}
	};

	onChangeImage = e => {
		const { id, files } = e.target;
		let detailImages = [];
		if (id === 'product-image-input') {
			$('.one-image').html(files[0].name);
			this.setState({
				imageUrl: files[0],
			});
		} else {
			_.forEach(files, file => {
				detailImages.push(file);
			});
			$('.multiple-images').html(`${files.length} images selected`);
			this.setState({ detailImages });
		}
	};

	onSaveProduct = () => {
		const {
			productName,
			productPrice,
			sale,
			description,
			salePercent,
			category,
			stock,
			imageUrl,
			detailImages,
			productId,
		} = this.state;
		if (
			productName === '' ||
			productName === null ||
			productId === '' ||
			productId === null ||
			productPrice === '' ||
			productPrice === null ||
			description === '' ||
			description === null ||
			stock === '' ||
			stock === null ||
			imageUrl === '' ||
			imageUrl === null ||
			detailImages === '' ||
			detailImages === null
		) {
			alert('please fill all the fields');
		} else {
			if (category === '' || category === null) {
				this.setState({ category: this.getCategoryId('King Prawns') });
			}
			setTimeout(() => {
				if (sale === 'true') {
					if (salePercent === '' || salePercent === null) {
						alert('please add sale percentage');
					} else {
						this.props.loaderState(true);
						this.props.addProduct(this.state);
					}
				} else {
					this.props.loaderState(true);
					this.props.addProduct(this.state);
				}
			}, 1000);
		}
	};

	getCategory = () => {
		const { categories, selected_product } = this.props;
		let category = null;
		_.forEach(categories, cat => {
			let prod_cat = selected_product ? selected_product.info.category : null;
			if (prod_cat === cat.id.trim()) {
				category = cat;
			}
		});

		return category;
	};

	render() {
		window
			.$(`#product-table`)
			.DataTable()
			.destroy();
		const { products, selected_product } = this.props;
		const { perPage, activePage, categories } = this.state;
		let category_products = this.getCategoryProducts(products);
		let selected_category = this.getCategory();
		//let productsData = paginationData(category_products, activePage, perPage);

		return (
			<ProductsComp
				onSaveProduct={() => this.onSaveProduct()}
				onChangeImage={e => this.onChangeImage(e)}
				onChangeInput={e => this.onChangeInput(e)}
				onDeleteProduct={id => this.onDeleteProduct(id)}
				onSelectProduct={product => this.onSelectProduct(product)}
				selected_product={selected_product}
				goto={path => this.goto(path)}
				categories={categories}
				onChangeCategory={category => this.onChangeCategory(category)}
				activePage={activePage}
				perPage={perPage}
				totalPages={category_products.length}
				products={category_products}
				fields={this.state}
				selected_category={selected_category}
				handlePageChange={pageNumber => this.handlePageChange(pageNumber)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		products: state.products.products,
		categories: state.categories.categories,
		selected_product: state.products.selected_product,
	};
};
export default connect(
	mapStateToProps,
	{ loaderState, getProducts, deleteProduct, selectedProduct, addProduct }
)(Products);
