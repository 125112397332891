import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paginationData } from '../../helpers';
import { getUsers, addToUsers, loaderState, userToEditOrDelete, updateUser, deleteUser } from '../../actions';

import UsersComp from '../../components/users';
class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			perPage: 4,
			first_name: null,
			last_name: null,
			email: null,
			contact_number: null,
			address: null,
			city: null,
			postal_code: null,
		};
	}

	async componentDidMount() {
		this.props.loaderState(true);
		await this.props.getUsers();
	}

	componentDidUpdate() {
		window.$(`#users-table`).DataTable({
			ordering: true,
			select: true,
			searching: true,
		});
	}

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber });
	};

	onChangeInput = event => {
		const { id, value } = event.target;
		if (id === 'first_name') {
			this.setState({
				first_name: value,
			});
		} else if (id === 'last_name') {
			this.setState({
				last_name: value,
			});
		} else if (id === 'city') {
			this.setState({
				city: value,
			});
		} else if (id === 'email') {
			this.setState({
				email: value,
			});
		} else if (id === 'address') {
			this.setState({
				address: value,
			});
		} else if (id === 'contact_number') {
			this.setState({
				contact_number: value,
			});
		} else if (id === 'postal_code') {
			this.setState({
				postal_code: value,
			});
		}
	};

	onSaveUser = () => {
		const { first_name, last_name, email, city } = this.state;
		if (
			first_name === '' ||
			last_name === '' ||
			email === '' ||
			city === '' ||
			first_name === null ||
			last_name === null ||
			email === null ||
			city === null
		) {
			alert('First Name, Last Name, Email and City are the required fields');
		} else {
			this.props.loaderState(true);
			this.props.addToUsers(this.state);
		}
	};

	onUpdateUser = () => {
		this.props.loaderState(true);
		this.props.updateUser(this.state);
	};

	userToDeleteOrEdit = data => {
		this.props.userToEditOrDelete(data);
	};

	onDeleteUser = id => {
		this.props.deleteUser(id);
	};

	render() {
		window
			.$(`#users-table`)
			.DataTable()
			.destroy();
		const { users, editOrDelete } = this.props;
		const { activePage, perPage } = this.state;
		return (
			<UsersComp
				activePage={activePage}
				perPage={perPage}
				totalPages={users.length}
				handlePageChange={pageNumber => this.handlePageChange(pageNumber)}
				onSaveClick={() => this.onSaveUser()}
				users={users}
				onDeleteUser={id => this.onDeleteUser(id)}
				onUpdateUser={() => this.onUpdateUser()}
				userToDeleteOrEdit={data => this.userToDeleteOrEdit(data)}
				editOrDelete={editOrDelete}
				toAdd={this.state}
				onChange={e => this.onChangeInput(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.users,
		editOrDelete: state.users.edit_or_delete,
	};
};

export default connect(
	mapStateToProps,
	{ getUsers, addToUsers, loaderState, userToEditOrDelete, updateUser, deleteUser }
)(Users);
