import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

function getUserInfo(id, users) {
	let userData = null;
	_.forEach(users, user => {
		if (user.id === id) {
			userData = user;
		}
	});
	return userData;
}

export default function Header({ user, logout, goto, pendingOrders, users }) {
	return (
		<div className="app-header">
			<div className="row dropdown">
				<div className="col-lg-9 col-md-9 col-sm-6 col-6"></div>
				<div className="col-lg-3 col-md-3 col-sm-6 col-6">
					<a onClick={() => goto('/profile')} id="nav">
						{/* <i className="fa fa-caret-down" /> */}
						{user ? user.info.first_name : null} {user ? user.info.last_name : null}
					</a>
					<a className="dropdown" id="nav">
						<span className="dropdown-toggle" data-toggle="dropdown">
							<i className="fa fa-bell" />
							<span className="badge">{pendingOrders ? pendingOrders.length : null}</span>
						</span>
						<ul className="dropdown-menu dropdown-menu-right" id="notifications">
							{pendingOrders
								? pendingOrders.map((order, index) => {
										let orderBy = getUserInfo(order.info.order_by, users);
										return (
											<li key={index}>
												<Link to={`/orders/${order.id}`}>
													<div className="media">
														<div className="media-body">
															<h5 className="media-heading">
																<small className="order-by">Order by</small>
																<b>
																	{orderBy ? orderBy.info.first_name + ' ' : null}
																	{orderBy ? orderBy.info.last_name : null}
																</b>
																<small className="date">
																	<i>{moment(order.info.order_date).format('LL')}</i>
																</small>
															</h5>
															<p style={{ fontSize: '0.9em' }}>
																Order amount is {order.info.order_amount} and{' '}
																{order.info.order_items.length} item(s)
															</p>
														</div>
													</div>
												</Link>
											</li>
										);
								  })
								: null}
						</ul>
					</a>
					<a id="nav" onClick={() => logout()}>
						logout
					</a>
				</div>
			</div>
		</div>
	);
}
