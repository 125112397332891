import React from 'react';

export default function DeleteUser({ user, onDeleteUser }) {
	let username = user ? user.info.first_name + ' ' + user.info.last_name : null;
	return (
		<div className="modal fade" id="delete-user" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">Update User Status</div>
					<div className="modal-body">
						{user ? (
							user.info.status === 'active' ? (
								<p>Do you want to block "{username}"?</p>
							) : (
								<p>Do you want to make "{username}" active?</p>
							)
						) : null}
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onDeleteUser(user)}
							className={
								user ? (user.info.status === 'active' ? 'btn btn-danger' : 'btn btn-success') : null
							}
							data-dismiss="modal"
						>
							Yes
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							No
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
