import React from 'react';
import _ from 'lodash';

function getCategory(id, categories) {
	let categoryData = null;
	_.forEach(categories, category => {
		if (category.id === id) {
			categoryData = category;
		}
	});
	return categoryData;
}

export default function ProductList({ products, goto, onSelectProduct, selected_category }) {
	return (
		<div className="table-responsive">
			<table id="product-table" className="table table-hover">
				<thead>
					<tr>
						<th>Id</th>
						<th>Product Name</th>
						<th>Product Price</th>
						<th>Stock</th>
						<th style={{ textAlign: 'center' }}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{products
						? products.map((product, index) => {
								return (
									<tr key={index}>
										<td>{product.id}</td>
										<td>{product.info.productName}</td>
										<td>{product.info.productPrice}</td>
										<td>{product.info.stock}</td>
										<td align="center">
											<a
												onClick={() => onSelectProduct(product)}
												data-toggle="modal"
												data-target="#delete-product"
											>
												<i
													style={{
														fontSize: '1.2em',
														cursor: 'pointer',
														marginRight: '20px',
														color: 'red',
													}}
													className="fa fa-trash"
													aria-hidden="true"
												></i>
											</a>
											<a
												onClick={() => goto(`/products/${product.id}`)}
												title="click to view product details"
											>
												<i
													style={{ fontSize: '1.4em', cursor: 'pointer' }}
													className="fa fa-eye"
													aria-hidden="true"
												></i>
											</a>
										</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</table>
		</div>
	);
}
