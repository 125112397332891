import React from "react";

export default function Login({ email, password, onInputChange, onSubmit }) {
  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first" style={{ marginTop: "30px" }}>
          <img src="images/logo.png" id="icon" alt="User Icon" />
        </div>

        <div>
          <input
            type="email"
            value={email}
            onChange={e => onInputChange(e)}
            id="email"
            className="fadeIn second login-email"
            placeholder="username"
          />
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => onInputChange(e)}
            className="fadeIn third"
            placeholder="password"
          />
          <input
            type="button"
            onClick={() => onSubmit()}
            className="fadeIn fourth"
            value="Log In"
          />
        </div>

        <div id="formFooter">
          <a className="underlineHover" href="#"></a>
        </div>
      </div>
    </div>
  );
}
