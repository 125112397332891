import { LOGGED_IN_USER } from '../constants/actionTypes';
const initialState = {
	userInfo: '',
};

function authReducer(state = initialState, action) {
	switch (action.type) {
		case LOGGED_IN_USER:
			return { ...state, userInfo: action.payload };
		default:
			return state;
	}
}

export default authReducer;
