import { ORDERS, ORDER_TO_UPDATE } from '../constants/actionTypes';
const initialState = {
	orders: [],
	order_to_update: '',
};

function ordersReducer(state = initialState, action) {
	switch (action.type) {
		case ORDERS:
			return { ...state, orders: action.payload };
		case ORDER_TO_UPDATE:
			return { ...state, order_to_update: action.payload };
		default:
			return state;
	}
}

export default ordersReducer;
