import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { getBanners, loaderState, updateBannerImage } from '../../actions';

import BannersComp from '../../components/Banners';

class Banners extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
		};
	}
	async componentDidMount() {
		this.props.loaderState(true);
		await this.props.getBanners();
	}

	onChangeFile = (e, idd) => {
		const { files } = e.target;
		$(document).ready(function() {
			$(`#${idd}`).html(files[0].name);
		});
		this.setState({ file: files[0] });
	};

	onUploadImage = id => {
		const { file } = this.state;
		if (file === null) {
			alert('Please select a file');
		} else {
			this.props.loaderState(true);
			this.props.updateBannerImage(id, this.state.file);
		}
	};

	render() {
		const { banners } = this.props;
		return (
			<BannersComp
				onUploadImage={id => this.onUploadImage(id)}
				onChangeFile={(e, id) => this.onChangeFile(e, id)}
				banners={banners}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		banners: state.banners.banners,
	};
};

export default connect(
	mapStateToProps,
	{ getBanners, loaderState, updateBannerImage }
)(Banners);
