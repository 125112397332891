import { PRODUCTS, SELECTED_PRODUCT } from '../constants/actionTypes';
const initialState = {
	products: [],
	selected_product: null,
};

function productsReducer(state = initialState, action) {
	switch (action.type) {
		case PRODUCTS:
			return { ...state, products: action.payload };
		case SELECTED_PRODUCT:
			return { ...state, selected_product: action.payload };
		default:
			return state;
	}
}

export default productsReducer;
