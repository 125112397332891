import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectedNav } from '../../actions';

import SidebarComp from '../../components/common/sidebar';

class Sidebar extends Component {
	onChangeNav = nav => {
		this.props.selectedNav(nav);
	};
	render() {
		const { nav } = this.props;
		return <SidebarComp onChangeNav={nav => this.onChangeNav(nav)} nav={nav} />;
	}
}

const mapStateToProps = state => {
	return {
		nav: state.selectedNav.nav,
	};
};
export default connect(
	mapStateToProps,
	{ selectedNav }
)(Sidebar);
