import React from 'react';

export default function EditModal({ handleChange, editOrDelete, onUpdateCategory, categoryName }) {
	return (
		<div className="modal fade" id="edit-category" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<span>Edit Category</span>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12 padding-0">
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										id="category-name"
										value={
											categoryName !== ''
												? categoryName
												: editOrDelete
												? editOrDelete.info.categoryName
												: null
										}
										onChange={e => handleChange(e)}
										placeholder="Enter Category Name"
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<div className="input-group">
										<span className="input-group-btn">
											<span className="btn btn-default btn-file">
												<label>Browse</label>
												<input
													onChange={e => handleChange(e, 'update')}
													accept="image/*"
													type="file"
													id="imgInp"
												/>
											</span>
										</span>
										<input
											type="text"
											placeholder="Upload new image for category"
											id="cat-id-update"
											className="form-control"
											disabled
										/>
									</div>
								</div>
							</div>
							{/* <div className="md-col-12">
								<div className="row">
									<div className="col-md-4 col-sm-2 col-4"></div>
									<div className="col-md-4 col-sm-8 col-4">
										<img width="250px" src={editOrDelete ? editOrDelete.info.imageUrl : null} />
									</div>
									<div className="col-md-4 col-sm-2 col-4"></div>
								</div>
							</div> */}
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onUpdateCategory(editOrDelete.id)}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Update
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
