import firebase from '../api/firebaseRef';
import { NOTIFICATION_TEXT } from '../constants/actionTypes';
import { loaderState } from './index';
import history from '../history';

export const getSystemData = () => dispatch => {
	let settingsRef = firebase.firestore().collection('settings');

	settingsRef.get().then(async text => {
		let notificationData = [];
		await text.docs.forEach(doc => {
			notificationData.push({
				id: doc.id,
				text: doc.data().text,
			});
		});
		dispatch({ type: NOTIFICATION_TEXT, payload: notificationData });
		dispatch(loaderState(false));
	});
};

export const updateNotificationtext = text => () => {
	let settingsRef = firebase.firestore().collection('settings');

	settingsRef
		.doc('notification')
		.update({ text: text })
		.then(res => {
			history.push('/settings');
			history.go('/settings');
		});
};

export const updateDeliveryInstructiontext = text => () => {
	let settingsRef = firebase.firestore().collection('settings');

	settingsRef
		.doc('delivery_instructions')
		.update({ text: text })
		.then(res => {
			history.push('/settings');
			history.go('/settings');
		});
};

export const updatePrivacytext = text => () => {
	let settingsRef = firebase.firestore().collection('settings');

	settingsRef
		.doc('privacy_text')
		.update({ text: text })
		.then(res => {
			history.push('/settings');
			history.go('/settings');
		});
};
