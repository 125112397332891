import firebase from "../api/firebaseRef";
import { ORDERS, ORDER_TO_UPDATE } from "../constants/actionTypes";
import { loaderState } from "./index";
import history from "../history";
import { Generate_PDF_URL } from "../constants/global-constant";
import { updateStock } from "./products";

export const getOrders = () => (dispatch) => {
  let orderRef = firebase.firestore().collection("order");
  orderRef
    .get()
    .then((orders) => {
      let ordersData = [];
      orders.docs.forEach((doc) => {
        ordersData.push({
          id: doc.id,
          info: doc.data(),
        });
      });
      dispatch({ type: ORDERS, payload: ordersData });
      dispatch(loaderState(false));
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const updateOrderStatus = (id, status) => (dispatch, getState) => {
  const { order_to_update } = getState().orders;
  dispatch(loaderState(true));
  let orderRef = firebase.firestore().collection("order");
  const data = {
    status: status,
  };
  orderRef
    .doc(id)
    .update(data)
    .then(async (res) => {
      await dispatch(getOrders());
      if (status === "Completed") {
        await dispatch(updateStock(order_to_update.info.order_items));
      }
      setTimeout(() => {
        dispatch(loaderState(false));
        history.go("/orders");
      }, 2000);
    })
    .catch((err) => {
      alert(err.message);
      dispatch(loaderState(false));
    });
};

export const orderToUpdate = (data) => (dispatch) => {
  dispatch({
    type: ORDER_TO_UPDATE,
    payload: data,
  });
};

export const createPDF = (orderId) => (dispatch) => {
  var anchor = document.createElement("a");
  anchor.href = `${Generate_PDF_URL}/generatePdf?orderId=${orderId}`;
  anchor.target = "_blank";
  anchor.download = "result.pdf";
  anchor.click();
  dispatch(loaderState(false));
};
