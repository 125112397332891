import firebase from '../api/firebaseRef';
import { BANNERS } from '../constants/actionTypes';
import { loaderState } from './index';
import history from '../history';

export const getBanners = () => dispatch => {
	let usersRef = firebase.firestore().collection('home_page_banner');
	usersRef
		.get()
		.then(async banners => {
			let bannersData = [];
			await banners.docs.forEach(doc => {
				bannersData.push({
					id: doc.id,
					info: doc.data(),
				});
			});
			await dispatch({ type: BANNERS, payload: bannersData });
			dispatch(loaderState(false));
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const updateBannerImage = (id, image) => dispatch => {
	let bannersRef = firebase.firestore().collection('home_page_banner');
	const storageRef = firebase.storage().ref('home_page_banner');

	const mainImage = storageRef.child(id);
	mainImage.put(image).then(snapshot => {
		mainImage.getDownloadURL().then(url => {
			let data = {
				image_url: url,
			};
			bannersRef
				.doc(id)
				.update(data)
				.then(async banner => {
					history.go('/banners');
				})
				.catch(err => {
					alert(err.message);
					dispatch(loaderState(false));
				});
		});
	});
};
