import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

class CustomPagination extends Component {
	render() {
		const { handlePageChange, perPage, totalPages, activePage } = this.props;
		return (
			<div>
				<Pagination
					prevPageText={<i className="fa fa-angle-left" />}
					nextPageText={<i className="fa fa-angle-right" />}
					firstPageText={<i className="fa fa-angle-double-left" />}
					lastPageText={<i className="fa fa-angle-double-right" />}
					activePage={activePage}
					itemsCountPerPage={perPage}
					totalItemsCount={totalPages}
					onChange={handlePageChange}
				/>
			</div>
		);
	}
}

export default CustomPagination;
