import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyBWpBXhuo4n82vUAt_RhUEEVwra_xeJVV4',
	authDomain: 'globus-a509e.firebaseapp.com',
	databaseURL: 'https://globus-a509e.firebaseio.com',
	projectId: 'globus-a509e',
	storageBucket: 'globus-a509e.appspot.com',
	messagingSenderId: '575199038508',
	appId: '1:575199038508:web:56c10dadfc2a5ef1919589',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
