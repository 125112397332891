import React from 'react';

export default function OrderItems({ items }) {
	return (
		<div className="table-responsive">
			<table id="order-item-table" className="table table-hover">
				<thead>
					<tr>
						<th>Item</th>
						<th>Quantity</th>
						<th>Price</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					{items
						? items.map((item, index) => {
								return (
									<tr key={index}>
										<td>{item.productName}</td>
										<td>{item.purchased_quantity}</td>
										<td>{item.productPrice}</td>
										<td>{item.total_price}</td>
									</tr>
								);
						  })
						: null}
				</tbody>
			</table>
		</div>
	);
}
