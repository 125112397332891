import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loaderState, selectedNav, getProducts, getCategories, getAdminUsers } from '../../actions';
import _ from 'lodash';

import DashboardComp from '../../components/dashboard';

class Dashboard extends Component {
	async componentDidMount() {
		this.props.loaderState(true);
		await this.props.getAdminUsers();
		await this.props.getProducts();
		await this.props.getCategories();
	}

	onChangeNav = nav => {
		this.props.selectedNav(nav);
	};

	getStatus = orders => {
		let canceled = 0,
			pending = 0,
			completed = 0,
			Processing = 0;
		_.forEach(orders, order => {
			let status = order.info.status;
			if (status === 'Processing') {
				Processing += 1;
			} else if (status === 'Pending') {
				pending += 1;
			} else if (status === 'Canceled') {
				canceled += 1;
			} else {
				completed += 1;
			}
		});

		return { completed, Processing, pending, canceled };
	};

	render() {
		const { orders } = this.props;
		const { completed, Processing, pending, canceled } = this.getStatus(orders);
		return (
			<DashboardComp
				onChangeNav={nav => this.onChangeNav(nav)}
				canceled={canceled}
				Processing={Processing}
				completed={completed}
				pending={pending}
				counts={this.props}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		orders: state.orders.orders,
		products: state.products.products.length,
		users: state.users.users.length,
		adminUsers: state.users.admin_users.length,
		categories: state.categories.categories.length,
	};
};

export default connect(
	mapStateToProps,
	{ loaderState, selectedNav, getProducts, getCategories, getAdminUsers }
)(Dashboard);
