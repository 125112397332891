import React from "react";
import OrderList from "./orderList";
import UpdateStatus from "./updateStatus";

export default function Orders({
	orders,
	users,
	order_to_update,
	orderToUpdate,
	getUserInfo,
	onChangeStatus,
	status,
	updateOrderStatus,
	onChangeStatusData,
	goto
}) {
	return (
		<div>
			<div className="form-group">
				<select className="form-control" onChange={e => onChangeStatusData(e)}>
					<option>Pending</option>
					<option>Processing</option>
					<option>Canceled</option>
					<option>Completed</option>
					<option>All</option>
				</select>
			</div>
			<div className="panel panel-default">
				<div className="panel-body" style={{ padding: "20px" }}>
					<OrderList
						goto={path => goto(path)}
						orderToUpdate={data => orderToUpdate(data)}
						orders={orders}
						users={users}
						getUserInfo={id => getUserInfo(id)}
					/>
				</div>
			</div>
			<UpdateStatus
				updateOrderStatus={(id, newStatus) => updateOrderStatus(id, newStatus)}
				onChangeStatus={e => onChangeStatus(e)}
				status={status}
				order_to_update={order_to_update}
			/>
		</div>
	);
}
