import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signOut, getOrders, getUsers } from '../../actions';
import history from '../../history';
import _ from 'lodash';

import HeaderComp from '../../components/common/header';

class Header extends Component {
	async componentDidMount() {
		await this.props.getOrders();
		await this.props.getUsers();
	}
	onLogout = () => {
		this.props.signOut();
	};

	goto = path => {
		history.push(path);
		history.go(path);
	};

	getPendingOrders = orders => {
		let pendingOrders = [];
		_.forEach(orders, order => {
			if (order.info.status === 'Pending') {
				pendingOrders.push(order);
			}
		});

		return pendingOrders;
	};

	render() {
		const { loggedInUser, orders, users } = this.props;
		let pendingOrders = this.getPendingOrders(orders);
		return (
			<HeaderComp
				pendingOrders={pendingOrders}
				goto={path => this.goto(path)}
				logout={() => this.onLogout()}
				users={users}
				user={loggedInUser}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		loggedInUser: state.auth.userInfo,
		orders: state.orders.orders,
		users: state.users.users,
	};
};

export default connect(
	mapStateToProps,
	{ signOut, getOrders, getUsers }
)(Header);
