import React from 'react';

export default function AddCategory({ onAddCategory, handleChange }) {
	return (
		<div className="panel" id="category-panel">
			<div className="panel-body">
				<div className="row">
					<div className="col-md-1"></div>
					<div className="col-md-4">
						<div className="form-group">
							<input
								type="text"
								className="form-control"
								id="category-name"
								onChange={e => handleChange(e)}
								placeholder="Enter Category Name"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group">
							<div className="input-group">
								<span className="input-group-btn">
									<span className="btn btn-default btn-file">
										<label>Browse</label>
										<input
											onChange={e => handleChange(e, 'update')}
											type="file"
											accept="image/*"
											id="imgInp"
										/>
									</span>
								</span>
								<input type="text" id="cat-id" className="form-control" disabled />
							</div>
						</div>
					</div>
					<div className="col-md-1">
						{/* <i className="fa fa-plus" aria-hidden="true"></i> */}
						<button onClick={() => onAddCategory()} className="btn btn-success">
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
