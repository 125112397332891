import React from 'react';
import AddEditProduct from './addEditProduct';

export default function ProductDetails({
	product,
	selected_category,
	categories,
	onChangeInput,
	onChangeImage,
	productName,
	productPrice,
	sale,
	salePercent,
	featuredProduct,
	category,
	stock,
	description,
	onSaveProduct,
	onClickUpdateImage,
	onUpdateProductImage,
	onAddNewImage,
	onClickAddImage,
}) {
	let images_count = product ? product.info.detailImages.length : 0;
	return (
		<div>
			<div className="panel panel-default">
				<div className="panel-body" style={{ padding: '5px' }}>
					<div className="product">
						<div className="product--title">
							<h2>{product ? product.info.productName : null}</h2>
						</div>
						<div className="product--body">
							<div className="product--image">
								<div className="icon-images">
									<img src={product ? product.info.imageUrl : null} />
									{product
										? product.info.detailImages.map((img, index) => {
												return index < 4 ? <img key={index} src={img} /> : null;
										  })
										: null}
									{images_count < 4 ? (
										<>
											<i
												className="fa fa-plus add-new-product-image"
												onClick={() => onClickAddImage()}
											/>
											<input
												type="file"
												onChange={e => onAddNewImage(e, images_count)}
												accept="image/*"
												className="add-product-image"
											/>
										</>
									) : null}
								</div>
								<div className="my-container">
									<img src={product ? product.info.imageUrl : null} className="display-img" />
									<div className="middle" onClick={() => onClickUpdateImage()}>
										<div className="text">Update Image</div>
									</div>
								</div>
								<input
									type="file"
									onChange={e => onUpdateProductImage(e)}
									accept="image/*"
									className="update-product-image"
								/>
							</div>
							<div className="product--details">
								{product ? (
									product.info.sale ? (
										<div className="product--price">
											<label>Product Price:</label>
											<span className="product--price_price">
												{product ? product.info.productPrice : null}
											</span>
											{product ? (
												product.info.sale ? (
													<span className="product--price_offer">
														SALE ({product.info.salePercent}%)
													</span>
												) : null
											) : null}
											<br />
											<label>After Sale Price:</label>
											<span className="product--price_price">
												{product ? product.info.productPriceAfterSale : null}
											</span>
										</div>
									) : (
										<div className="product--price">
											<label>Product Price:</label>
											<span className="product--price_price">
												{product ? product.info.productPrice : null}
											</span>
										</div>
									)
								) : null}

								<div className="product-category">
									<label>Category:</label>
									<span>{selected_category ? selected_category.info.categoryName : null}</span>
								</div>
								<div className="product-featured">
									<label> Featured Product:</label>
									<span>{product ? (product.info.featuredProduct ? 'Yes' : 'No') : null}</span>
								</div>
								<div className="product--desc">
									<p>
										<label>Description:</label>
										<br />
										{product ? product.info.description : null}
									</p>
								</div>
								<div className="product--size">
									<label>Stock:</label>
									<span>{product ? product.info.stock : null}</span>
								</div>
								<div className="add-to-cart" data-toggle="modal" data-target="#add-edit-product">
									<button className="btn btn--cart">Update Product</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddEditProduct
				onChangeInput={e => onChangeInput(e)}
				onChangeImage={e => onChangeImage(e)}
				onSaveProduct={() => onSaveProduct()}
				product={product}
				selected_category={selected_category}
				categories={categories}
				heading="Edit Product"
				productName={productName}
				productPrice={productPrice}
				sale={sale}
				salePercent={salePercent}
				featuredProduct={featuredProduct}
				category={category}
				stock={stock}
				description={description}
			/>
		</div>
	);
}
