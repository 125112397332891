import React from 'react';

export default function AddEditProduct({
	heading,
	onSaveProduct,
	categories,
	product,
	onChangeInput,
	onChangeImage,
	selected_category,
	productName,
	productPrice,
	sale,
	salePercent,
	featuredProduct,
	category,
	stock,
	description,
}) {
	return (
		<div className="modal fade" id="add-edit-product" role="dialog">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal">
							&times;
						</button>
						<h4 className="modal-title">{heading}</h4>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="category">Category</label>
									<select
										className="form-control"
										value={
											category && category !== null
												? category.name
												: selected_category
												? selected_category.info.categoryName
												: null
										}
										id="category"
										onChange={e => onChangeInput(e)}
									>
										{categories.map(cat => {
											return (
												<option key={cat.id}>
													{cat.name ? cat.name : cat.info.categoryName}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="product-name">Product Name</label>
									<input
										onChange={e => onChangeInput(e)}
										type="text"
										className="form-control"
										placeholder="product name"
										id="product-name"
										value={
											productName !== null
												? productName
												: product
												? product.info.productName
												: null
										}
									/>
								</div>
							</div>
							{!product ? (
								<div className="col-sm-6">
									<div className="form-group">
										<label htmlFor="product-id">Product Id</label>
										<input
											onChange={e => onChangeInput(e)}
											type="text"
											className="form-control"
											placeholder="product Id"
											id="product-id"
										/>
									</div>
								</div>
							) : null}
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="product-price">Product Price</label>
									<input
										onChange={e => onChangeInput(e)}
										type="text"
										className="form-control"
										placeholder="product price"
										id="product-price"
										value={
											productPrice !== null
												? productPrice
												: product
												? product.info.productPrice
												: null
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="featured-product">Featured</label>
									<select
										className="form-control"
										onChange={e => onChangeInput(e)}
										id="featured-product"
										value={
											featuredProduct !== null
												? featuredProduct
												: product
												? product.info.featuredProduct
												: null
										}
									>
										<option>false</option>
										<option>true</option>
									</select>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="sale">Sale</label>
									<select
										value={sale !== null ? sale : product ? product.info.sale : null}
										className="form-control"
										id="sale"
										onChange={e => onChangeInput(e)}
									>
										<option>false</option>
										<option>true</option>
									</select>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="sale-percentage">Sale Percentage</label>
									<input
										onChange={e => onChangeInput(e)}
										type="text"
										className="form-control"
										placeholder="sale percentage"
										id="sale-percentage"
										value={
											salePercent !== null
												? salePercent
												: product
												? product.info.salePercent
												: null
										}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<label htmlFor="stock">Stock</label>
									<input
										onChange={e => onChangeInput(e)}
										type="text"
										className="form-control"
										placeholder="stock"
										id="stock"
										value={stock !== null ? stock : product ? product.info.stock : null}
									/>
								</div>
							</div>
							{!product ? (
								<>
									<div className="col-sm-6">
										<div className="form-group">
											<label htmlFor="description">Product Image</label>
											<label className="form-control one-image" id="product-image">
												Click here to upload image
												<input
													onChange={e => onChangeImage(e)}
													type="file"
													id="product-image-input"
													accept="image/*"
												/>
											</label>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label htmlFor="description">Detail Product Images</label>
											<label className="form-control multiple-images" id="product-image">
												Click here to upload images
												<input
													onChange={e => onChangeImage(e)}
													type="file"
													id="details-images"
													multiple
													accept="image/*"
													style={{ display: 'none' }}
												/>
											</label>
										</div>
									</div>
								</>
							) : null}
							<div className="col-sm-12">
								<div className="form-group">
									<label htmlFor="description">Product Description</label>
									<textarea
										onChange={e => onChangeInput(e)}
										rows="5"
										type="text"
										value={
											description !== null
												? description
												: product
												? product.info.description
												: null
										}
										className="form-control"
										placeholder="description"
										id="description"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							onClick={() => onSaveProduct()}
							type="button"
							className="btn btn-success"
							data-dismiss="modal"
						>
							Save
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
