import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../App.css';
import history from '../history';
import cookie from 'react-cookies';
import Routes from '../routes';
import Sidebar from '../containers/sideBar';
import Login from '../containers/login';
import Header from '../containers/header';

class App extends Component {
	componentDidMount() {
		// if(!cookie.load('userId')){
		// 	history.push('/')
		// 	history.go('/')
		// }
	}
	render() {
		const { isLoading } = this.props;
		const pathname = window.location.pathname;

		let opacity = isLoading ? 0.5 : 1;
		let pointerEvents = isLoading ? 'none' : '';

		return (
			<BrowserRouter>
				<div style={{ pointerEvents, opacity }}>
					{pathname === '/' ? (
						<>
							<Login />
							{/* <Loader display={isLoading} /> */}
						</>
					) : (
						<>
							<Header />
							<div className="page-wrapper chiller-theme toggled">
								<Sidebar />
								<div className="page-content">
									<Routes />
								</div>
							</div>
						</>
					)}
					{/* <Loader display={isLoading} /> */}
				</div>
			</BrowserRouter>
		);
	}
}
const mapStateToProps = state => {
	return {
		isLoading: state.loader.isLoading,
	};
};

export default connect(mapStateToProps, {})(App);
