import React from 'react';
import EditUser from './editUser';
import UserCard from './userCard';
import ConfirmationModal from './confirmationModal';
import DeleteUser from './deleteUser';
import AddUser from './addUser';
import Pagination from '../common/pagination';
import UserList from './userList';
export default function Users({
	users,
	toAdd,
	onChange,
	onDeleteUser,
	onSaveClick,
	editOrDelete,
	userToDeleteOrEdit,
	onUpdateUser,
	perPage,
	totalPages,
	handlePageChange,
	activePage,
}) {
	return (
		<>
			<div className="add-user-div">
				<button
					data-toggle="modal"
					style={{ width: 'auto' }}
					data-target="#add-user"
					className="btn btn-primary"
				>
					Add New User
				</button>
			</div>
			<div className="row mb-3">
				<div className="panel panel-default">
					<div className="panel-body" style={{ padding: '20px' }}>
						<UserList userToDeleteOrEdit={data => userToDeleteOrEdit(data)} users={users} />
					</div>
				</div>
				{/* <UserCard userToDeleteOrEdit={data => userToDeleteOrEdit(data)} users={users} /> */}
			</div>

			{/* <div id="pagination">
				<Pagination
					activePage={activePage}
					handlePageChange={handlePageChange}
					perPage={perPage}
					totalPages={totalPages}
				/>
			</div> */}
			<EditUser
				onUpdateUser={() => onUpdateUser()}
				user={editOrDelete}
				toAdd={toAdd}
				onChange={e => onChange(e)}
			/>
			<AddUser onSaveClick={() => onSaveClick()} toAdd={toAdd} onChange={e => onChange(e)} />
			<DeleteUser onDeleteUser={id => onDeleteUser(id)} user={editOrDelete} />
			<ConfirmationModal />
		</>
	);
}
