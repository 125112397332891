import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import _ from 'lodash';
import {
	getCategories,
	addCategory,
	loaderState,
	deleteCategory,
	categoryToEditOrDetele,
	updateCategory,
} from '../../actions';
import { paginationData } from '../../helpers';

import CategoriesComp from '../../components/categories';

class Categories extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			perPage: 5,
			category_name: '',
			file: null,
			search_text: '',
		};
	}

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber });
	};

	async componentDidMount() {
		this.props.loaderState(true);
		await this.props.getCategories();
	}

	handleChange = (e, update) => {
		const { id } = e.target;
		if (id === 'category-name') {
			this.setState({
				category_name: e.target.value,
			});
		} else {
			const { files } = e.target;
			if (update === 'update') {
				$(document).ready(function() {
					$(`#cat-id-update`).val(files[0].name);
				});
			} else {
				$(document).ready(function() {
					$(`#cat-id`).val(files[0].name);
				});
			}

			this.setState({
				file: files[0],
			});
		}
	};

	onAddCategory = () => {
		const { category_name, file } = this.state;
		if (category_name === '' || file === null) {
			alert('Please add category name and select an image');
		} else {
			this.props.loaderState(true);
			this.props.addCategory(category_name, file);
		}
	};

	onDeleteCategory = id => {
		this.props.loaderState(true);
		this.props.deleteCategory(id);
	};

	onEditOrDeleteCategory = category => {
		this.props.categoryToEditOrDetele(category);
	};

	onUpdateCategory = id => {
		const { category_name, file } = this.state;
		let data = {
			categoryName: category_name,
			image: file,
		};
		if (category_name === '' && file === null) {
			alert('please update one of fields or press cancel');
		} else {
			this.props.loaderState(true);
			this.props.updateCategory(id, data);
		}
	};

	onSearchCategory = () => {
		const { categories } = this.props;
		const { search_text } = this.state;
		let searchData = [];
		if (search_text !== '') {
			_.forEach(categories, cat => {
				if (cat.info.categoryName.toLowerCase().includes(search_text.toLowerCase())) {
					searchData.push(cat);
				}
			});
		} else {
			return categories;
		}

		return searchData;
	};

	onSearchTextChange = ({ target }) => {
		this.setState({
			search_text: target.value,
		});
	};

	render() {
		const { editOrDelete } = this.props;
		const { perPage, activePage, category_name } = this.state;
		let categories = this.onSearchCategory();
		let paginationDataNew = paginationData(categories, activePage, perPage);
		return (
			<CategoriesComp
				categoryName={category_name}
				onUpdateCategory={id => this.onUpdateCategory(id)}
				onDeleteCategory={id => this.onDeleteCategory(id)}
				onEditDelete={cat => this.onEditOrDeleteCategory(cat)}
				onAddCategory={() => this.onAddCategory()}
				handleChange={(e, update) => this.handleChange(e, update)}
				editOrDelete={editOrDelete}
				categories={paginationDataNew}
				totalPages={categories.length}
				perPage={perPage}
				activePage={activePage}
				handlePageChange={pageNumber => this.handlePageChange(pageNumber)}
				onSearchTextChange={e => this.onSearchTextChange(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		categories: state.categories.categories,
		editOrDelete: state.categories.category_edit_or_delete,
	};
};

export default connect(
	mapStateToProps,
	{ getCategories, addCategory, loaderState, deleteCategory, categoryToEditOrDetele, updateCategory }
)(Categories);
