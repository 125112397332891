import React from 'react';
import Quantities from './quantities';

export default function Dashboard({ completed, Processing, pending, canceled, counts, onChangeNav }) {
	return (
		<>
			<div className="row">
				<div className="col-lg-3">
					<div className="card-counter primary">
						<i className="fa fa-clock-o"></i>
						<span className="count-numbers">{pending}</span>
						<span className="count-name">Pending Orders</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="card-counter danger">
						<i className="fa fa-spinner"></i>
						<span className="count-numbers">{Processing}</span>
						<span className="count-name">Processing Orders</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="card-counter success">
						<i className="fa fa-times"></i>
						<span className="count-numbers">{canceled}</span>
						<span className="count-name">Canceled Orders</span>
					</div>
				</div>

				<div className="col-lg-3">
					<div className="card-counter info">
						<i className="fa fa-check"></i>
						<span className="count-numbers">{completed}</span>
						<span className="count-name">Completed Orders</span>
					</div>
				</div>
			</div>
			<div className="row">
				<Quantities counts={counts} onChangeNav={nav => onChangeNav(nav)} />
			</div>
		</>
	);
}
