import React from 'react';

export default function BannerCard({ banner, onChangeFile, onUploadImage }) {
	return (
		<>
			<div className="image-label">
				<span>{banner.id.replace(/_/g, ' ').toUpperCase()}</span>
			</div>
			<figure className="imghvr-hinge-left">
				<img src={banner.info.image_url} className="img-responsive" />
				<figcaption>
					<h3>Upload New Image</h3>
					<div className="btn-group">
						<div className="upload">
							<input
								type="file"
								onChange={e => onChangeFile(e, banner.id)}
								className="banner-input"
								name="upload"
								accept="image/*"
							/>
							<span id={banner.id} className="fileName">
								Click here to upload file
							</span>
						</div>
					</div>
					<input
						type="button"
						onClick={() => onUploadImage(banner.id)}
						id="upload-file"
						className="uploadButton btn btn-success"
						value="Submit File"
					/>
				</figcaption>
			</figure>
		</>
	);
}
