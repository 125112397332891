import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateOrderStatus,
  createPDF,
  loaderState,
  updateStock,
} from "../../actions";
import _ from "lodash";

import OrderDetailsComp from "../../components/orders/orderDetails";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.$(`#order-item-table`).DataTable({
      ordering: true,
      select: true,
      searching: true,
    });
  }

  componentDidUpdate() {
    window.$(`#order-item-table`).DataTable({
      ordering: true,
      select: true,
      searching: true,
    });
  }
  getOrderDetails = (orders) => {
    let id = window.location.pathname.split("/").pop(-1);
    let selectedOrder = null;
    _.forEach(orders, (order) => {
      if (order.id.trim() === id.trim()) {
        selectedOrder = order;
      }
    });

    return selectedOrder;
  };

  getUserInfo = (id, users) => {
    let userData = null;
    _.forEach(users, (user) => {
      if (user.id === id) {
        userData = user;
      }
    });
    return userData;
  };

  getTotalAmount = (order) => {
    let total = 0;
    _.forEach(order.info.order_items, (itm) => {
      total += parseFloat(itm.total_price);
    });

    return parseFloat(total).toFixed(2);
  };

  onCreatePDF = (orderId) => {
    this.props.loaderState(true);
    this.props.createPDF(orderId);
  };

  render() {
    window.$(`#order-item-table`).DataTable().destroy();
    const { orders, users, updateOrderStatus } = this.props;
    let selectedOrder = this.getOrderDetails(orders);
    let user = this.getUserInfo(selectedOrder.info.order_by, users);
    let total_amount = this.getTotalAmount(selectedOrder);

    return (
      <OrderDetailsComp
        updateOrderStatus={(id, status) => updateOrderStatus(id, status)}
        order={selectedOrder}
        user={user}
        total_amount={total_amount}
        onCreatePDF={(orderId) => this.onCreatePDF(orderId)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders.orders,
    users: state.users.users,
  };
};

export default connect(mapStateToProps, {
  updateOrderStatus,
  createPDF,
  loaderState,
  updateStock,
})(OrderDetails);
