import React from 'react';
import BannerCard from './bannerCard';

export default function Banners({ banners, onChangeFile, onUploadImage }) {
	return (
		<div className="container" style={{ textAlign: 'center' }}>
			{banners.map(banner => {
				return (
					<>
						<BannerCard
							onUploadImage={id => onUploadImage(id)}
							onChangeFile={(e, id) => onChangeFile(e, id)}
							banner={banner}
						/>
					</>
				);
			})}
		</div>
	);
}
