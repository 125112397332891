import React from 'react';
import List from './list';
import Pagination from '../common/pagination';
import DeleteModal from './deleteModal';
import EditModal from './editModal';
import AddCategory from './addCategory';

export default function Categories({
	categories,
	onAddCategory,
	activePage,
	handlePageChange,
	perPage,
	totalPages,
	handleChange,
	onDeleteCategory,
	onEditDelete,
	editOrDelete,
	onUpdateCategory,
	categoryName,
	onSearchTextChange,
}) {
	return (
		<div>
			<div className="container">
				<AddCategory onAddCategory={() => onAddCategory()} handleChange={e => handleChange(e)} />

				<List
					onSearchTextChange={e => onSearchTextChange(e)}
					categories={categories}
					onEditDelete={cat => onEditDelete(cat)}
				/>
			</div>
			<div id="pagination">
				<Pagination
					activePage={activePage}
					handlePageChange={handlePageChange}
					perPage={perPage}
					totalPages={totalPages}
				/>
			</div>
			<DeleteModal editOrDelete={editOrDelete} onDeleteCategory={id => onDeleteCategory(id)} />
			<EditModal
				categoryName={categoryName}
				onUpdateCategory={id => onUpdateCategory(id)}
				editOrDelete={editOrDelete}
				handleChange={(e, update) => handleChange(e, update)}
			/>
		</div>
	);
}
