import { LOADER, SELECTED_NAV } from '../constants/actionTypes';

export const loaderState = state => dispatch => {
	dispatch({ type: LOADER, payload: state });
};

export const selectedNav = nav => dispatch => {
	dispatch({ type: SELECTED_NAV, payload: nav });
};

export * from './authentication';
export * from './users';
export * from './orders';
export * from './categories';
export * from './banners';
export * from './products';
export * from './settings';
