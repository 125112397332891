import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Users from '../containers/users';
import AdminUsers from '../containers/adminUsers';
import Orders from '../containers/orders';
import Products from '../containers/products';
import Banners from '../containers/banners';
import Categories from '../containers/categories';
import Dashboard from '../containers/dashboard';
import ProductDetails from '../containers/products/productDetails';
import OrderDetails from '../containers/orders/orderDetails';
import UserProfile from '../containers/userProfile';
import Settings from '../containers/settings';
import cookie from 'react-cookies';
import history from '../history';

class Routes extends Component {
	componentDidMount() {
		if (!cookie.load('userId')) {
			history.push('/');
			history.go('/');
		}
	}

	render() {
		return (
			<Switch>
				<Route path="/dashboard" component={Dashboard} />
				<Route path="/users" component={Users} />
				<Route path="/admin users" component={AdminUsers} />
				<Route path="/orders/:id" component={OrderDetails} />
				<Route path="/orders" component={Orders} />
				<Route path="/products/:id" component={ProductDetails} />
				<Route path="/products" component={Products} />
				<Route path="/banners" component={Banners} />
				<Route path="/categories" component={Categories} />
				<Route path="/profile" component={UserProfile} />
				<Route path="/settings" component={Settings} />
			</Switch>
		);
	}
}

export default Routes;
