import React from 'react';

export default function DeleteModal({ editOrDelete, onDeleteCategory }) {
	return (
		<div className="modal fade" id="delete-category" role="dialog">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header"></div>
					<div className="modal-body">
						<p>
							Are you sure you want to delete "{editOrDelete ? editOrDelete.info.categoryName : null}"
							category
						</p>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onDeleteCategory(editOrDelete ? editOrDelete.id : null)}
							className="btn btn-danger"
							data-dismiss="modal"
						>
							Confirm
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
