import React from 'react';

export default function AddUser({ onChange, toAdd, onSaveClick }) {
	return (
		<div className="modal fade" id="add-user" role="dialog">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">Add User</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="first_name">First Name</label>
									<input
										type="text"
										placeholder="First Name"
										value={toAdd.first_name}
										onChange={e => onChange(e)}
										className="form-control"
										id="first_name"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="last_name">Last Name</label>
									<input
										type="text"
										placeholder="Last Name"
										value={toAdd.last_name}
										onChange={e => onChange(e)}
										className="form-control"
										id="last_name"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="email">Email</label>

									<input
										type="email"
										placeholder="Email"
										value={toAdd.email}
										onChange={e => onChange(e)}
										className="form-control"
										id="email"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="contact_number">Contact Number</label>
									<input
										type="text"
										placeholder="Contact Number"
										value={toAdd.contact_number}
										onChange={e => onChange(e)}
										className="form-control"
										id="contact_number"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="address">Address</label>
									<input
										type="text"
										placeholder="Address"
										value={toAdd.address}
										onChange={e => onChange(e)}
										className="form-control"
										id="address"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="city">City</label>
									<input
										type="text"
										placeholder="City"
										value={toAdd.city}
										onChange={e => onChange(e)}
										className="form-control"
										id="city"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="postal _code">Postal Code</label>
									<input
										type="text"
										placeholder="Postal Code"
										value={toAdd.postal_code}
										onChange={e => onChange(e)}
										className="form-control"
										id="postal_code"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							onClick={() => onSaveClick()}
							className="btn btn-success"
							data-dismiss="modal"
						>
							Save
						</button>
						<button type="button" className="btn btn-default" data-dismiss="modal">
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
