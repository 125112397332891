import React from 'react';
import moment from 'moment';
import UpdateProfile from './updateProfile';

export default function UserProfile({ data, onChangeInput, info, onUpdateProfile }) {
	return (
		<>
			<div className="row">
				<div className="panel panel-default">
					<div className="panel-heading">
						<h4>User Profile</h4>
					</div>
					<div className="panel-body">
						<div className="col-md-4 col-xs-12 col-sm-6 col-lg-4" id="profile-pic">
							<img
								alt="User Pic"
								src={
									data.info.profileImage
										? data.info.profileImage
										: 'https://x1.xingassets.com/assets/frontend_minified/img/users/nobody_m.original.jpg'
								}
								id="profile-image1"
								className="img-circle img-responsive"
							/>
							{/* <span className="pic-update-btn">
								<span>Update Picture</span>
								<input type="file" id="profile-image-input" accept="image/*" />
							</span> */}
						</div>
						<div className="col-md-8 col-xs-12 col-sm-6 col-lg-8" style={{ marginTop: '5%' }}>
							<div className="container">
								<h2>
									{data.info.first_name} {data.info.last_name}
								</h2>
							</div>
							<hr />
							<ul className="container details">
								<li>
									<p>
										<span
											className="fa fa-map-marker"
											style={{ fontSize: '1.4em', marginRight: '10px' }}
										></span>
										{data.info.address}, {data.info.city}
									</p>
								</li>
								<li>
									<p>
										<span
											className="fa fa-envelope"
											style={{ fontSize: '1.4em', marginRight: '10px' }}
										></span>
										{data.info.email}
									</p>
								</li>
								<li>
									<p>
										<span
											className="fa fa-phone-square"
											style={{ fontSize: '1.4em', marginRight: '10px' }}
										></span>
										{data.info.contactNumber}
									</p>
								</li>
								<li>
									<p>
										<span
											className="fa fa-address-card"
											style={{ fontSize: '1.4em', marginRight: '10px' }}
										></span>
										{data.info.postalCode}
									</p>
								</li>
							</ul>
							<hr />
							<div className="col-sm-5 col-xs-6 tital ">
								Created On: {moment(data.info.created_on).format('LL')}
							</div>
							<button
								data-toggle="modal"
								data-target="#update-profile"
								className="btn btn-default"
								style={{ float: 'right' }}
							>
								Update Profile
							</button>
						</div>
					</div>
				</div>
			</div>
			<UpdateProfile
				data={data}
				info={info}
				onUpdateProfile={() => onUpdateProfile()}
				onChangeInput={e => onChangeInput(e)}
			/>
		</>
	);
}
