import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authenticateUser, loaderState } from '../../actions';

import LoginComp from '../../components/login';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
		};
	}
	onInputChange = e => {
		const { value, id } = e.target;
		if (id === 'email') {
			this.setState({
				email: value,
			});
		} else {
			this.setState({
				password: value,
			});
		}
	};
	onSubmit = () => {
		const { email, password } = this.state;
		if (email !== '' && password !== '') {
			this.props.loaderState(true);
			this.props.authenticateUser(email, password);
		} else {
			alert('please enter email and password');
		}
	};
	render() {
		const { email, password } = this.state;
		return (
			<LoginComp
				email={email}
				password={password}
				onSubmit={e => this.onSubmit(e)}
				onInputChange={e => this.onInputChange(e)}
			/>
		);
	}
}

export default connect(
	null,
	{ authenticateUser, loaderState }
)(Login);
