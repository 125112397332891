import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paginationData } from '../../helpers';
import {
	getAdminUsers,
	addToAdminUsers,
	loaderState,
	userToEditOrDelete,
	updateAdminUser,
	deleteAdminUser,
} from '../../actions';

import UsersComp from '../../components/users';
class AdminUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			perPage: 4,
			first_name: null,
			last_name: null,
			email: null,
			contact_number: null,
			address: null,
			city: null,
			postal_code: null,
		};
	}

	componentDidMount() {
		this.props.loaderState(true);
		this.props.getAdminUsers();
	}
	componentDidUpdate() {
		window.$(`#users-table`).DataTable({
			ordering: true,
			select: true,
			searching: true,
		});
	}

	handlePageChange = pageNumber => {
		this.setState({ activePage: pageNumber });
	};

	onChangeInput = event => {
		const { id, value } = event.target;
		if (id === 'first_name') {
			this.setState({
				first_name: value,
			});
		} else if (id === 'last_name') {
			this.setState({
				last_name: value,
			});
		} else if (id === 'city') {
			this.setState({
				city: value,
			});
		} else if (id === 'email') {
			this.setState({
				email: value,
			});
		} else if (id === 'address') {
			this.setState({
				address: value,
			});
		} else if (id === 'contact_number') {
			this.setState({
				contact_number: value,
			});
		} else if (id === 'postal_code') {
			this.setState({
				postal_code: value,
			});
		}
	};

	onSaveAdminUser = () => {
		const { first_name, last_name, email, city } = this.state;
		if (first_name === '' || last_name === '' || email === '' || city === '') {
			alert('First Name, Last Name, Email and City are the required fields');
		} else {
			this.props.loaderState(true);
			this.props.addToAdminUsers(this.state);
		}
	};

	onUpdateUser = () => {
		this.props.loaderState(true);
		this.props.updateAdminUser(this.state);
	};

	userToDeleteOrEdit = data => {
		this.props.userToEditOrDelete(data);
	};

	onDeleteUser = id => {
		this.props.deleteAdminUser(id);
	};

	render() {
		window
			.$(`#users-table`)
			.DataTable()
			.destroy();
		const { users, editOrDelete } = this.props;
		const { activePage, perPage } = this.state;
		return (
			<UsersComp
				activePage={activePage}
				perPage={perPage}
				totalPages={users.length}
				handlePageChange={pageNumber => this.handlePageChange(pageNumber)}
				onSaveClick={() => this.onSaveAdminUser()}
				users={users}
				onDeleteUser={id => this.onDeleteUser(id)}
				onUpdateUser={() => this.onUpdateUser()}
				userToDeleteOrEdit={data => this.userToDeleteOrEdit(data)}
				editOrDelete={editOrDelete}
				toAdd={this.state}
				onChange={e => this.onChangeInput(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		users: state.users.admin_users,
		editOrDelete: state.users.edit_or_delete,
	};
};

export default connect(
	mapStateToProps,
	{ getAdminUsers, addToAdminUsers, loaderState, userToEditOrDelete, updateAdminUser, deleteAdminUser }
)(AdminUsers);
