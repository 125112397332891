import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { allPossiableSubStrings } from '../../helpers';
import $ from 'jquery';
import { updateProduct, loaderState, selectedProduct, updateProductImage, addNewImage } from '../../actions';

import ProductDetailsComp from '../../components/products/productDetails';

class ProductDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedProduct: '',
			category: null,
			productName: null,
			featuredProduct: null,
			imageUrl: null,
			description: null,
			detailImages: null,
			productPrice: null,
			sale: null,
			salePercent: null,
			stock: null,
			indexes: [],
			updatedImages: null,
		};
	}

	componentDidMount() {
		let id = window.location.pathname.split('/').pop(-1);
		let product = this.getSelectedProduct(id);
		this.setState({ selectedProduct: product });
		this.props.selectedProduct(product);
	}

	getSelectedProduct = id => {
		const { products } = this.props;
		let selectedProduct = null;
		_.forEach(products, product => {
			if (id.trim() === product.id.trim()) {
				selectedProduct = product;
			}
		});

		return selectedProduct;
	};

	getCategory = () => {
		const { categories } = this.props;
		const { selectedProduct } = this.state;
		let category = null;
		_.forEach(categories, cat => {
			let prod_cat = selectedProduct ? selectedProduct.info.category : null;
			if (prod_cat === cat.id.trim()) {
				category = cat;
			}
		});

		return category;
	};

	getCategoryId = name => {
		const { categories } = this.props;
		let category = {};
		_.forEach(categories, cat => {
			if (cat.info.categoryName === name) {
				category.id = cat.id;
				category.name = cat.info.categoryName;
			}
		});

		return category;
	};

	onChangeInput = e => {
		const { id, value } = e.target;
		if (id === 'category') {
			this.setState({
				category: this.getCategoryId(value),
			});
		} else if (id === 'product-name') {
			this.setState({ productName: value, indexes: allPossiableSubStrings(value) });
		} else if (id === 'product-price') {
			this.setState({ productPrice: value });
		} else if (id === 'featured-product') {
			this.setState({ featuredProduct: value });
		} else if (id === 'stock') {
			this.setState({ stock: value });
		} else if (id === 'sale') {
			this.setState({ sale: value });
		} else if (id === 'sale-percentage') {
			this.setState({ salePercent: value });
		} else {
			this.setState({
				description: value,
			});
		}
	};

	onChangeImage = e => {
		const { id, files } = e.target;
		let detailImages = [];
		if (id === 'product-image-input') {
			$('.one-image').html(files[0].name);
			this.setState({
				imageUrl: files[0],
			});
		} else {
			_.forEach(files, file => {
				detailImages.push(file);
			});
			$('.multiple-images').html(`${files.length} images selected`);
			this.setState({ detailImages });
		}
	};

	onClickUpdateImage = () => {
		$('.update-product-image').trigger('click');
	};

	onClickAddImage = () => {
		$('.add-product-image').trigger('click');
	};

	onUpdateProductImage = ({ target }) => {
		let src = $('.display-img').attr('src');
		this.props.loaderState(true);
		this.props.updateProductImage(src, target.files[0]);
	};

	onAddNewImage = (e, index) => {
		this.props.loaderState(true);
		this.props.addNewImage(e.target.files[0], index);
	};

	onUpdateProduct = () => {
		const {
			productName,
			productPrice,
			sale,
			salePercent,
			featuredProduct,
			category,
			stock,
			description,
			indexes,
		} = this.state;
		let data = {};
		if (productName && productName !== '') {
			data.productName = productName;
			data.indexes = indexes;
		}
		if (productPrice && productPrice !== '') {
			data.productPrice = productPrice;
		}
		if (sale && sale !== '') {
			data.sale = sale;
		}
		if (salePercent && salePercent !== '') {
			data.salePercent = salePercent;
		}
		if (featuredProduct && featuredProduct !== '') {
			data.featuredProduct = featuredProduct;
		}
		if (category && category !== '') {
			data.category = category.id;
		}
		if (stock && stock !== '') {
			data.stock = stock;
		}
		if (description && description !== '') {
			data.description = description;
		}
		this.props.loaderState(true);
		this.props.updateProduct(data);
	};

	render() {
		const { selectedProduct } = this.state;
		const { categories } = this.props;
		return (
			<ProductDetailsComp
				onUpdateProductImage={e => this.onUpdateProductImage(e)}
				onClickUpdateImage={() => this.onClickUpdateImage()}
				onClickAddImage={() => this.onClickAddImage()}
				onAddNewImage={(e, index) => this.onAddNewImage(e, index)}
				onChangeImage={e => this.onChangeImage(e)}
				onChangeInput={e => this.onChangeInput(e)}
				categories={categories}
				{...this.state}
				onSaveProduct={() => this.onUpdateProduct()}
				product={selectedProduct}
				selected_category={this.getCategory()}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		products: state.products.products,
		categories: state.categories.categories,
	};
};

export default connect(
	mapStateToProps,
	{ updateProduct, loaderState, selectedProduct, updateProductImage, addNewImage }
)(ProductDetails);
