import React, { Component } from 'react';
import {
	getSystemData,
	loaderState,
	updateNotificationtext,
	updateDeliveryInstructiontext,
	updatePrivacytext,
} from '../../actions';
import { connect } from 'react-redux';
import SettingsComp from '../../components/settings';

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notification: null,
			privacy: null,
			delivery_instructions: null,
		};
	}
	async componentDidMount() {
		await this.props.loaderState(true);
		await this.props.getSystemData();
	}

	onChangeText = ({ target }) => {
		const { id, value } = target;
		if (id === 'delivery_instructions') {
			this.setState({ delivery_instructions: value });
		} else if (id === 'privacy') {
			this.setState({
				privacy: value,
			});
		} else {
			this.setState({
				notification: value,
			});
		}
	};

	onUpdateNotification = () => {
		const { notification } = this.state;
		if (!notification || notification === '') {
			this.setState({
				notification: this.props.text[1].text,
			});
		} else {
			this.props.loaderState(true);
			this.props.updateNotificationtext(notification);
		}
	};

	onUpdatePrivacy = () => {
		const { privacy } = this.state;
		if (!privacy || privacy === '') {
			this.setState({
				privacy: this.props.text[2].text,
			});
		} else {
			this.props.loaderState(true);
			this.props.updatePrivacytext(privacy);
		}
	};

	onUpdateDeliveryInstructions = () => {
		const { delivery_instructions } = this.state;
		if (!delivery_instructions || delivery_instructions === '') {
			this.setState({
				delivery_instructions: this.props.text[0].text,
			});
		} else {
			this.props.loaderState(true);
			this.props.updateDeliveryInstructiontext(delivery_instructions);
		}
	};

	render() {
		const { text } = this.props;
		const { privacy, notification, delivery_instructions } = this.state;
		return (
			<SettingsComp
				onUpdateNotification={() => this.onUpdateNotification()}
				onUpdatePrivacy={() => this.onUpdatePrivacy()}
				onUpdateDeliveryInstructions={() => this.onUpdateDeliveryInstructions()}
				text={text}
				notification={notification}
				privacy={privacy}
				delivery_instructions={delivery_instructions}
				onChangeText={e => this.onChangeText(e)}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		text: state.settings.text,
	};
};
export default connect(
	mapStateToProps,
	{ loaderState, getSystemData, updateNotificationtext, updateDeliveryInstructiontext, updatePrivacytext }
)(Settings);
