import firebase from '../api/firebaseRef';
import { USERS, ADMIN_USERS, LOGGED_IN_USER, USER_EDIT_OR_DELETE } from '../constants/actionTypes';
import { loaderState } from './index';
import history from '../history';
import moment from 'moment';
import cookie from 'react-cookies';

export const getUsers = () => dispatch => {
	let usersRef = firebase.firestore().collection('users');
	usersRef
		.get()
		.then(async usrs => {
			let Usersdata = [];
			await usrs.docs.forEach(doc => {
				Usersdata.push({
					id: doc.id,
					info: doc.data(),
				});
			});
			await dispatch({ type: USERS, payload: Usersdata });
			dispatch(loaderState(false));
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const addToUsers = userData => dispatch => {
	let usersRef = firebase.firestore().collection('users');
	usersRef
		.add({
			first_name: userData.first_name,
			last_name: userData.last_name,
			email: userData.email,
			postalCode: userData.postal_code,
			city: userData.city,
			address: userData.address,
			contactNumber: userData.contact_number,
			created_on: moment().format('DD/MM/YYYY'),
			status: 'active',
		})
		.then(async usr => {
			await dispatch(getUsers);
			history.go('/users');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const deleteUser = user => dispatch => {
	let userRef = firebase.firestore().collection('users');
	let status = user.info.status === 'active' ? 'blocked' : 'active';
	userRef
		.doc(user.id)
		.update({ status: status })
		.then(async res => {
			history.go('/users');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const updateUser = newData => (dispatch, getState) => {
	let userRef = firebase.firestore().collection('users');
	const data = getState().users.edit_or_delete;
	let dataToSave = {
		address: newData.address !== null && newData.address !== '' ? newData.address : data.info.address,
		city: newData.city !== null && newData.city !== '' ? newData.city : data.info.city,
		first_name:
			newData.first_name !== null && newData.first_name !== '' ? newData.first_name : data.info.first_name,
		last_name: newData.last_name !== null && newData.last_name !== '' ? newData.last_name : data.info.last_name,
		email: newData.email !== null && newData.email !== '' ? newData.email : data.info.email,
		postalCode:
			newData.postal_code !== null && newData.postal_code !== '' ? newData.postal_code : data.info.postalCode,
		contactNumber:
			newData.contact_number !== null && newData.contact_number !== ''
				? newData.contact_number
				: data.info.contactNumber,
	};
	userRef
		.doc(data.id)
		.update(dataToSave)
		.then(async res => {
			history.go('/users');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const getAdminUsers = () => dispatch => {
	let usersRef = firebase.firestore().collection('admin_users');
	usersRef
		.get()
		.then(async usrs => {
			let Usersdata = [];
			await usrs.docs.forEach(doc => {
				Usersdata.push({
					id: doc.id,
					info: doc.data(),
				});
			});
			await dispatch({ type: ADMIN_USERS, payload: Usersdata });
			dispatch(loaderState(false));
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const getAdminUsersById = id => dispatch => {
	let usersRef = firebase
		.firestore()
		.collection('admin_users')
		.doc(id);
	usersRef
		.get()
		.then(async doc => {
			if (!doc.exists) {
				alert(`you don't have access to this site`);
				dispatch(loaderState(false));
			} else {
				if (doc.data().status === 'active') {
					const expires = new Date();
					expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
					cookie.save('userId', id, { path: '/', expires, maxAge: 1000 });
					await dispatch({ type: LOGGED_IN_USER, payload: { id: doc.id, info: doc.data() } });
					history.push('/dashboard');
					history.go('/dashboard');
				} else {
					alert(`you have been blocked, kindly contact your administration`);
					dispatch(loaderState(false));
				}
			}
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const addToAdminUsers = userData => dispatch => {
	firebase
		.auth()
		.createUserWithEmailAndPassword(userData.email, 'password')
		.then(newUser => {
			let usersRef = firebase
				.firestore()
				.collection('admin_users')
				.doc(newUser.user.uid.toString());
			usersRef
				.set({
					first_name: userData.first_name,
					last_name: userData.last_name,
					email: userData.email,
					postalCode: userData.postal_code,
					city: userData.city,
					address: userData.address,
					contactNumber: userData.contact_number,
					created_on: moment().format('DD/MM/YYYY'),
					status: 'active',
				})
				.then(user => {
					firebase
						.auth()
						.sendPasswordResetEmail(userData.email)
						.then(async usr => {
							alert('Password reset email has been send to the user!');
							await dispatch(getAdminUsers());
							dispatch(loaderState(false));
						})
						.catch(err => {
							alert(err.message);
							dispatch(loaderState(false));
						});
				});
		})
		.catch(error => {
			alert(error.message);
			dispatch(loaderState(false));
		});
};

export const deleteAdminUser = user => dispatch => {
	let userRef = firebase.firestore().collection('admin_users');
	let status = user.info.status === 'active' ? 'blocked' : 'active';
	userRef
		.doc(user.id)
		.update({ status: status })
		.then(async res => {
			await dispatch(getAdminUsers());
			history.go('/admin users');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const updateAdminUser = newData => (dispatch, getState) => {
	let userRef = firebase.firestore().collection('admin_users');
	const data = getState().users.edit_or_delete;
	let dataToSave = {
		address: newData.address !== null && newData.address !== '' ? newData.address : data.info.address,
		city: newData.city !== null && newData.city !== '' ? newData.city : data.info.city,
		first_name:
			newData.first_name !== null && newData.first_name !== '' ? newData.first_name : data.info.first_name,
		last_name: newData.last_name !== null && newData.last_name !== '' ? newData.last_name : data.info.last_name,
		email: newData.email !== null && newData.email !== '' ? newData.email : data.info.email,
		postalCode:
			newData.postal_code !== null && newData.postal_code !== '' ? newData.postal_code : data.info.postalCode,
		contactNumber:
			newData.contact_number !== null && newData.contact_number !== ''
				? newData.contact_number
				: data.info.contactNumber,
	};
	userRef
		.doc(data.id)
		.update(dataToSave)
		.then(async res => {
			history.go('/admin users');
		})
		.catch(err => {
			alert(err.message);
			dispatch(loaderState(false));
		});
};

export const userToEditOrDelete = data => dispatch => {
	dispatch({ type: USER_EDIT_OR_DELETE, payload: data });
};
