import { BANNERS } from '../constants/actionTypes';
const initialState = {
	banners: [],
};

function bannersReducer(state = initialState, action) {
	switch (action.type) {
		case BANNERS:
			return { ...state, banners: action.payload };
		default:
			return state;
	}
}

export default bannersReducer;
