import { NOTIFICATION_TEXT } from '../constants/actionTypes';
const initialState = {
	text: [],
};

function settingsReducer(state = initialState, action) {
	switch (action.type) {
		case NOTIFICATION_TEXT:
			return { ...state, text: action.payload };
		default:
			return state;
	}
}

export default settingsReducer;
